import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Avatar,
  Drawer,
  List,
  Stack,
  Toolbar,
  IconButton,
  Collapse,
  styled,
} from "@mui/material";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import colorConfigs from "../../../configs/colorConfigs";
import sizeConfigs from "../../../configs/sizeConfigs";
import SidebarItem from "./sidebarItem/SidebarItem";
import SidebarItemCollapse from "./sidebarItemCollapse/SidebarItemCollapse";
import Topbar from "../../layout/topbar/Topbar";
import adminRoutes from "../../../routes/adminRoutes";
import { api } from "../../../utils/api";
import { IBuilder } from "../../../@types/interface/Builder.interface";
import BuilderContext from "../../../contexts/builderContext/BuilderContext";
import AuthContext from "../../../contexts/authContext/authContext";
import { ROLES } from "../../../constants/roles/Roles";
import { replaceUnderScore } from "../../../utils/commonFunction/replaceUnderScore";
// import axios from "axios";

interface SideBarProps {
  toggleSidebar: () => void;
  opens: boolean;
}
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  const nameParts = name.split(" ");
  const initials =
    nameParts.length > 1 ? `${nameParts[0][0]}${nameParts[1][0]}` : name[0];
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
}

const AdminSidebar: React.FC<SideBarProps> = ({ toggleSidebar, opens }) => {
  const [open, setOpen] = useState(true);
  const [builderData, setBuilderData] = useState<IBuilder>();
  const { setBuilderDetails } = useContext(BuilderContext);
  const { user } = useContext(AuthContext);

  const handleToggleSidebar = () => {
    setOpen(!open);
    toggleSidebar();
  };

  const fetchBuildersByID = useCallback(async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const builderId = queryParams.get("cid");
      if (!builderId) {
        throw new Error("Builder ID is missing in the query parameters.");
      }
      const response = await api.builder.getBuilderByID(builderId);
      setBuilderData(response);
      setBuilderDetails(response);
      console.log("newResponse-->", response.builder_name);
    } catch (error) {
      console.log("Error while fetching builderByID:");
    }
  }, []);

  useEffect(() => {
    fetchBuildersByID();
  }, [fetchBuildersByID]);

  return (
    <>
      <Topbar sidebarOpen={open} toggleSidebar={handleToggleSidebar} />
      <Drawer
        variant="permanent"
        sx={{
          width: open ? sizeConfigs.sidebar.width : 0,
          flexShrink: 0,
          transition: "width 0.2s ease-in-out",
          "& .MuiDrawer-paper": {
            width: open ? sizeConfigs.sidebar.width : 0,
            boxSizing: "border-box",
            borderRight: "0px",
            backgroundColor: colorConfigs.adminSidebar.bg,
            color: colorConfigs.adminSidebar.color,
            overflowX: "hidden",
          },
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* abhishek will fix it */}
          {/* <IconButton onClick={handleToggleSidebar} style={{ color: "white" }}>
            {open ? <ChevronLeft /> : <Menu />}
          </IconButton> */}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                marginTop: 40,
              }}
            >
              <img
                src={builderData?.builder_logo}
                alt="logo"
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
        </Toolbar>
        <h4
          style={{
            textAlign: "center",
            marginTop: 40,
            marginBottom: 30,
            letterSpacing: 4,
          }}
        >
          {replaceUnderScore(user?.role || "Admin")}
        </h4>

        <Avatar
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            height: "80px",
            width: "80px",
            textAlign: "center",
          }}
          {...stringAvatar(user?.full_name || "Admin User")}
        />

        <h3
          style={{
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "500",
            marginTop: "10px",
          }}
        >
          {user?.full_name || "Super Admin"}
        </h3>

        {/* Sidebar content */}
        <List disablePadding>
          {adminRoutes.map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                user?.role === ROLES.project_manager ? null : (
                  <SidebarItemCollapse
                    item={route}
                    id={builderData?._id || ""}
                    key={index}
                  />
                )
              ) : user?.role === ROLES.project_manager ? (
                route.state === "assigned_projects" ||
                route.state === "forwarded_enqury" ? (
                  <SidebarItem
                    item={{
                      ...route,
                      path: `${route.path}?cid=${builderData?._id}`,
                    }}
                    key={index}
                    iconColor={colorConfigs.adminSidebar.color}
                    activeColor={colorConfigs.adminSidebar.activeBg}
                    hoverColor={colorConfigs.adminSidebar.hoverBg}
                  />
                ) : null
              ) : route.state === "assigned_projects" ||
                route.state === "forwarded_enqury" ? null : route.state ===
                  "employee" || route.state === "reports" ? (
                user?.role === ROLES.builder_admin ||
                user?.role === ROLES.super_admin ? (
                  <SidebarItem
                    item={{
                      ...route,
                      path: `${route.path}?cid=${builderData?._id}`,
                    }}
                    key={index}
                    iconColor={colorConfigs.adminSidebar.color}
                    activeColor={colorConfigs.adminSidebar.activeBg}
                    hoverColor={colorConfigs.adminSidebar.hoverBg}
                  />
                ) : null
              ) : (
                <SidebarItem
                  item={{
                    ...route,
                    path: `${route.path}?cid=${builderData?._id}`,
                  }}
                  key={index}
                  iconColor={colorConfigs.adminSidebar.color}
                  activeColor={colorConfigs.adminSidebar.activeBg}
                  hoverColor={colorConfigs.adminSidebar.hoverBg}
                />
              )
            ) : null
          )}
        </List>
      </Drawer>
    </>
  );
};

export default AdminSidebar;
