import { Grid, IconButton, TextField } from "@mui/material";
import React from "react";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { IFacilityDescriptionProps } from "../../../../../../@types/props/facility.props";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const FacilityOverview: React.FC<IFacilityDescriptionProps> = ({
  facility_details,
  handleAddMore,
  handleChangeFacility,
  index,
  handleRemove
}) => {
  return (
    <Grid
      container
      spacing={2}
      alignItems={"center"}
      style={{ marginBottom: 10 }}
    >
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Add Aminity"
          name="facility_details"
          value={facility_details}
          onChange={(event) => handleChangeFacility(event, index)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
        <IconButton
          className="dark-green-button"
          style={{ marginRight: 4 }}
          onClick={handleAddMore}
        >
          <AddTaskIcon />
        </IconButton>
        <IconButton
          className="dark-red-button"
          style={{ marginRight: 4, color: "red" }}
          onClick={() => handleRemove(index)}
        >
          <DeleteOutlineIcon /> 
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default FacilityOverview;
