import React, { useCallback, useEffect, useState } from "react";
import { ILayout } from "../../../../@types/interface/layout.interface";
import { IProject } from "../../../../@types/interface/Projects";
import { api } from "../../../../utils/api";
import ProjctLayout from "../../../shared/projectLayout/ProjectLayout";
import { useLocation } from "react-router-dom";
import { ROLES } from "../../../../constants/roles/Roles";
import { Box, Tab, Tabs } from "@mui/material";
import a11yProps from "../../../../constants/tabPanelProps/ayProps";
import TabPanel from "../../../shared/tabPanel/TabPanel";
import ProjectEnquiry from "../../projects/projectDetails/projectEnquiry/ProjectEnquiry";
import ProjectOffers from "../../projects/projectDetails/projectOffer/ProjectOffers";
import ViewProject from "../../../shared/viewProject/ViewProject";

const Viewlayout = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const projectId = queryParams.get("pid");
  const builderId = queryParams.get("cid");

  const [rectanglePositions, setRectanglePositions] = React.useState<ILayout[]>(
    []
  );
  const [value, setValue] = useState<number>(0);
  const [project, setProject] = React.useState<IProject | null>(null);

  const location = useLocation();

  const isOnAdminPage = location.pathname.startsWith("/admin");
  const isOnOwnerPage = location.pathname.startsWith("/landlord");
  const isOnRetailerPage = location.pathname.startsWith("/reseller");
  const isOnMarketerPage = location.pathname.startsWith("/marketer");

  const getRole = useCallback(() => {
    if (isOnAdminPage) {
      return ROLES.builder_admin;
    } else if (isOnOwnerPage) {
      return ROLES.landlord;
    } else if (isOnRetailerPage) {
      return ROLES.reseller;
    } else if (isOnMarketerPage) {
      return ROLES.marketer;
    }
  }, [isOnAdminPage, isOnOwnerPage, isOnRetailerPage, isOnMarketerPage]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getProjectDetails = React.useCallback(async () => {
    const payload = {
      project_object_id: projectId,
      role: getRole(),
      admin_object_id: builderId,
    };
    const projectDetails = await api.project.getSelectiveProjectDetails(
      payload
    );
    console.log(projectDetails);
    if (projectDetails) {
      setProject(projectDetails.project_details);
      setRectanglePositions(projectDetails.layout_list);
    }
  }, [projectId, getRole, builderId]);

  useEffect(() => {
    getProjectDetails();
  }, [getProjectDetails]);
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                border: "none",
                backgroundColor: "#558b2f",
              },
            }}
            className="glb-tab-panel"
          >
            <Tab label="Layout Image" {...a11yProps(0)} />
            <Tab label="Project Details" {...a11yProps(1)} />
            {/* <Tab label="Notes" {...a11yProps(2)} /> */}
            <Tab label="Total Leads" {...a11yProps(2)} />
            <Tab label="Add Offers" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {projectId ? (
            <ProjctLayout
              layoutPosition={rectanglePositions}
              mode="ALL"
              plotId={projectId || ""}
              project={project}
              key={0}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {projectId ? (
            <ViewProject project={project} projectId={projectId} />
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {projectId ? <ProjectEnquiry projectId={projectId} /> : null}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {projectId && <ProjectOffers projectId={projectId} />}
        </TabPanel>
      </Box>
      {/* <ProjctLayout
        layoutPosition={rectanglePositions}
        mode="ALL"
        plotId={projectId || ""}
        project={project}
        key={1}
      /> */}
    </div>
  );
};

export default Viewlayout;
