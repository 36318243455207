export const ROLES = {
  super_admin: "SUPER_ADMIN",
  builder_admin: "BUILDER_ADMIN",
  project_manager: "PROJECT_MANAGER",
  telecaller: "TELECALLER",
  channel_partner: "CHANNEL_PARNER",
  landlord: "LANDLORD",
  reseller: "RESELLER",
  marketer: "MARKETER",
};
