import React from 'react'
import { IUser } from '../../../../../@types/interface/admin.interface'

const NotLoggedInMsg = ({data}: {data: IUser}) => {
  return (
    <div>
        {data.last_login_date  ? data.last_login_date : 'Not Logged in yet'}
    </div>
  )
}

export default NotLoggedInMsg;