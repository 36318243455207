import React, { useContext, useEffect } from 'react'
import UIContext from '../../../../contexts/uiContext/UIContext'
import ReportForm from '../addReport/ReportForm'

const CityReport = () => {
  const {setDashboardHeader} = useContext(UIContext)

  useEffect(() => {
    setDashboardHeader('City Report')
  }, [setDashboardHeader])
  return (
    <div>
      <ReportForm/>
    </div>
  )
}

export default CityReport