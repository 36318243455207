import React, { useCallback, useContext, useEffect, useState } from "react";
import DataGrid from "../../../shared/dataGrid/DataGrid";
import { PlotsDefs } from "../../../../constants/plots/plotsDefs";
import { IProject } from "../../../../@types/interface/Projects";
import UIContext from "../../../../contexts/uiContext/UIContext";
import { IPagination } from "../../../../@types/interface/pagination";
import { api } from "../../../../utils/api";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PhaseDefs } from "../../../../constants/phase/phaseDefs";

const PhaseList = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const navigate = useNavigate();
  const [allPhaseList, setAllPhaseList] = useState<IProject[]>([]);
  const [allPhasePagination, setAllPhasePagination] = useState<IPagination>({
    currentPage: 1,
    pageCount: 1,
  });

  const queryParams = new URLSearchParams(window.location.search);
  const projectId = queryParams.get("pid");

  const handleNavigateToAddPhase = () => {
    navigate(`/add-phase?pid=${projectId}`);
  };

  const getAllPhaseList = useCallback(async () => {
    try {
      let response: any = {};
      const filter = {
        project_object_id: projectId,
        page: allPhasePagination.currentPage,
      };
      response = await api.project.getPhaseList(filter);
      if (response) {
        setAllPhaseList(response.result);
        setAllPhasePagination(response.pagination);
        console.log("response", response);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  }, [allPhasePagination.currentPage, projectId]);

  useEffect(() => {
    getAllPhaseList();
  }, [getAllPhaseList]);

  useEffect(() => {
    setDashboardHeader("Phase List");
  }, [setDashboardHeader]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
          marginBottom: "20px",
          gap: "5px",
        }}
      >
        {/* <Download onClick={downloadAllProjectsFullData} /> */}
        <Button
          className="btn"
          variant="contained"
          onClick={handleNavigateToAddPhase}
          endIcon={<ApartmentIcon />}
        >
          Add Phase
        </Button>
      </div>
      <DataGrid colDefs={PhaseDefs} rowData={allPhaseList} key={2}></DataGrid>
    </div>
  );
};

export default PhaseList;
