import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { ProjectOptions } from "../../../../constants/addPlotsFormFields/ProjectOptions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProjectFormMultipleSelectField = ({
  projectDetails,
  label,
  name,
  width,
  type,
  className,
  handleProjectTypeChange,
  required = false,
}: any) => {
  return (
    <div className={className}>
      <label style={{ fontSize: "16px" }}>
        {label}
        {required ? (
          <span style={{ color: "red", fontSize: 20, fontWeight: "bold" }}>
            *
          </span>
        ) : null}
      </label>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id={name}
          multiple
          value={projectDetails.project_type}
          onChange={handleProjectTypeChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {ProjectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox
                checked={projectDetails.project_type.indexOf(option.value) > -1}
              />
              <ListItemText primary={option.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ProjectFormMultipleSelectField;
