import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BuilderContext from "../../../../../../../contexts/builderContext/BuilderContext";
import UIContext from "../../../../../../../contexts/uiContext/UIContext";
import { IFollowUp } from "../../../../../../../@types/interface/followup.interface";
import { api } from "../../../../../../../utils/api";
import AuthContext from "../../../../../../../contexts/authContext/authContext";

const AddFollowUp = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const { user } = useContext(AuthContext);
  const queryParams = new URLSearchParams(window.location.search);
  const enquiryId = queryParams.get("eid");

  const [followupDetails, setFollowupDetails] = useState<IFollowUp>({
    enquiry_object_id: enquiryId as string,
    followby_object_id: user?._id as string,
    followUp_note: "",
    followUp_date: new Date().toString(),
    followUp_time: "",
  });

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFollowupDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleCreateFollowup = async () => {
    try {
      const response = await api.followUp.createFollowUp(followupDetails);
      
      if (response) {
        setFollowupDetails(response);
        setHasSubmitted(true);
        alert("Follow up created successfully!");
      }
    } catch (error) {
      console.error("Error creating follow up:", error);
      alert("There was an error creating the follow up. Please try again.");
    }
  };

  const handleButtonClick = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleCreateFollowup();
  };

  useEffect(() => {
    setDashboardHeader("Follow up details");
  }, [setDashboardHeader]);

  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionDetails sx={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "left",
            }}
          >
            <label>Follow Up Date:</label>
            <TextField
              variant="outlined"
              sx={{ width: "600px", marginBottom: "20px" }}
              type="date"
              name="followUp_date"
              onChange={handleChange}
              disabled={hasSubmitted}
            ></TextField>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "left",
            }}
          >
            <label>Follow up note:</label>
            <TextField
              variant="outlined"
              sx={{ width: "600px", marginBottom: "20px" }}
              name="followUp_note"
              onChange={handleChange}
              disabled={hasSubmitted}
            ></TextField>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "left",
            }}
          >
            <label>Follow up time:</label>
            <TextField
              variant="outlined"
              type="time"
              sx={{ width: "600px", marginBottom: "20px" }}
              name="followUp_time"
              onChange={handleChange}
              disabled={hasSubmitted}
            ></TextField>
          </div>
          {/* <div style={{display: 'flex', justifyContent: "space-between", alignItems: "left"}}>
            <label>Created by:</label>
       <TextField variant="outlined" sx={{width: "600px", marginBottom: "20px"}}></TextField>
            </div> */}
          <Button
            type="submit"
            variant="contained"
            className="btn"
            onClick={handleButtonClick}
          >
            Submit
          </Button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AddFollowUp;
