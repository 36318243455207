import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Grid } from '@mui/material';
import axios from 'axios';
import { api } from '../../../../../utils/api';

interface IOffer {
  _id?: string;
  project_object_id: any;
  offer_title: string;
  offer_details: string;
  criteria: string;
  validity_upto: any;
}

const ProjectOffers = ({projectId}:any) => {
  const [offerData, setOfferData] = useState<IOffer>({
    project_object_id: '',
    offer_title: '',
    offer_details: '',
    criteria: '',
    validity_upto: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOfferData({
      ...offerData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
        const response = await api.offers.addOffer(offerData)
      alert('Offer created successfully!');
      console.log('Offer created:', response.data);
    } catch (error) {
      console.error('Error creating offer:', error);
      alert('Failed to create offer');
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: '95%',
        margin: '0 auto',
        padding: 3,
        border: '1px solid #e0e0e0',
        borderRadius: 2,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >


      <Grid container spacing={2} alignItems="center">
        {/* Project Object ID */}
        <Grid item xs={4}>
          <Typography variant="body1">Project Object ID</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            name="project_object_id"
            value={offerData.project_object_id}
            onChange={handleInputChange}
            fullWidth
            required
            variant="outlined"
            size="small"
          />
        </Grid>

        {/* Offer Title */}
        <Grid item xs={4}>
          <Typography variant="body1">Offer Title</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            name="offer_title"
            value={offerData.offer_title}
            onChange={handleInputChange}
            fullWidth
            required
            variant="outlined"
            size="small"
          />
        </Grid>

        {/* Offer Details */}
        <Grid item xs={4}>
          <Typography variant="body1">Offer Details</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            name="offer_details"
            value={offerData.offer_details}
            onChange={handleInputChange}
            fullWidth
            required
            multiline
            rows={3}
            variant="outlined"
            size="small"
          />
        </Grid>

        {/* Criteria */}
        <Grid item xs={4}>
          <Typography variant="body1">Criteria</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            name="criteria"
            value={offerData.criteria}
            onChange={handleInputChange}
            fullWidth
            required
            variant="outlined"
            size="small"
          />
        </Grid>

        {/* Validity Upto */}
        <Grid item xs={4}>
          <Typography variant="body1">Validity Upto</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            name="validity_upto"
            type="date"
            value={offerData.validity_upto}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>

      <Box mt={3} textAlign="right">
        <Button type="submit" variant="contained" color="success">
          Add Offer
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectOffers;
