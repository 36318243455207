
/*eslint-disable*/

import React, { useCallback, useContext, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import { Button, Chip } from "@mui/material";
// import { IAddplots } from "../../../../../@types/interface/Builder.interface";
// import { api } from "../../../../../utils/api";
// import BuilderContext from "../../../../../contexts/builderContext/BuilderContext";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import UIContext from "../../../contexts/uiContext/UIContext";
import { defaultProjectForm } from "../../../defaultValue/projectForm/ProjectForm";
import { IProject } from "../../../@types/interface/Projects";
import { IViewProjectProps } from "../../../@types/props/ViewProject.props";
// import UIContext from "../../../../../contexts/uiContext/UIContext";
// import { IProject } from "../../../../../@types/interface/Projects";
// import { defaultProjectForm } from "../../../../../defaultValue/projectForm/ProjectForm";

// const VisuallyHiddenInput = styled("input")({
//   clip: "rect(0 0 0 0)",
//   clipPath: "inset(50%)",
//   height: 1,
//   overflow: "hidden",
//   position: "absolute",
//   bottom: 0,
//   left: 0,
//   whiteSpace: "nowrap",
//   width: 1,
// });

const ViewProject = ({project,projectId}:IViewProjectProps) => {
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState<"success" | "error">(
    "success"
  );
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [ readOnly , setReadOnly] = useState<boolean>(true)
  const [details, setDetails] = useState<IProject>(defaultProjectForm);
  const { setDashboardHeader } = useContext(UIContext);
  const states = [
    "Andhra Pradesh",
    "Assam",
    "Bihar",
    "Chattisgarh",
    "Haryana",
    "Gujrat",
    "Kerala",
    "Maharashtra",
    "Tamilnadu",
    "Telangana",
    "Uttarakhand",
    "West Bengal",
  ];

  // const handleChange = (event: any) => {
  //   const { name, value } = event.target;
  //   setDetails((prevDetails: any) => ({
  //     ...prevDetails,
  //     [name]: value,
  //   }));
  // };
  // const handleChange = (event: any) => {
  //   const { name, value } = event.target;
  //   setDetails((prevDetails: any) => ({
  //     ...prevDetails,
  //     [name]: value,
  //   }));
  // };
  const handleClick = () => {
    setReadOnly(!readOnly)
    
  }

 

  return (
    <form >
      <div>
        <p style={{fontSize: "16px", fontWeight: "500"}}>Project Details</p>
        
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={snackbarType}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        <div>
          <div style={{display: "flex", justifyContent: 'right', alignItems: "right"}}><Button className="btn" variant="contained" onClick={handleClick} endIcon={<EditOutlinedIcon/>}>Edit</Button></div>
          <Accordion
            defaultExpanded
            style={{ display: "block", marginTop: "20px" }}
            elevation={4}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            ></AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "space-between",
                }}
              >
                <label style={{ fontSize: "16px", marginLeft: "40px" }}>
                  Project Name:
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  sx={{
                    width: "60%",
                    marginRight: "40px",
                    marginBottom: "10px",
                  }}
                  inputProps={
                    { readOnly: readOnly }
                  }
                  value={project?.project_name}
                  onChange={(event) => {
                    const value = event.target.value;
                    setDetails({
                      ...details,
                      project_name : value
                    });
                  }}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "space-between",
                }}
              >
                <label style={{ fontSize: "16px", marginLeft: "40px" }}>
                  Description:
                </label>
                <textarea
                  style={{
                    maxWidth: "60%",
                    minWidth: "60%",
                    maxHeight: "150px",
                    marginRight: "40px",
                    marginBottom: "10px",
                    padding: "5px",
                    fontSize: "18px",
                    outline: "1px",
                  }}
                readOnly={readOnly}
                value={project?.description}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "space-between",
                }}
              >
                <label style={{ fontSize: "16px", marginLeft: "40px" }}>
                  Number of Plots:
                </label>
                <TextField
                  id="outlined-basic"
                  type="number"
                  variant="outlined"
                  sx={{
                    width: "60%",
                    marginRight: "40px",
                    marginLeft: "20px",
                    marginBottom: "10px",
                  }}
                 value={project?.total_plots}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "space-between",
                }}
              >
                <label style={{ fontSize: "16px", marginLeft: "40px" }}>
                  Price/sq:
                </label>
                <TextField
                  id="outlined-basic"
                  type="number"
                  variant="outlined"
                  
                  sx={{
                    width: "60%",
                    marginRight: "40px",
                    marginLeft: "20px",
                    marginBottom: "10px",
                  }}
                 value={project?.price_per_sq}
                required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "space-between",
                }}
              >
                <label style={{ fontSize: "16px", marginLeft: "40px" }}>
                  Total sq.ft:
                </label>
                <TextField
                  id="outlined-basic"
                  type="number"
                  variant="outlined"
                  sx={{
                    width: "60%",
                    marginRight: "40px",
                    marginLeft: "20px",
                    marginBottom: "10px",
                  }}
                  // value={totalSqFeet === null ? "" : totalSqFeet}
                 
                  // onChange={(event) => {
                  //   const value = event.target.value;
                  //   setTotalSqFeet(value === "" ? null : Number(value));
                  // }}
                 value={project?.total_sq_feet}
                  required
                />
              </div>
            </AccordionDetails>
            {/* <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "40px",
              }}
            >
              <Button
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  marginLeft: 40,
                 
                }}
                endIcon={<UploadOutlinedIcon/>}
                className="btn"
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
              >
                Upload Plot Image
                <VisuallyHiddenInput
                  type="file"
                  onChange={(event) => {
                    const file = event.target.files?.[0];
                    if (file) {
                      setUploadedFile(file);
                    }
                  }}
                  required
                />
              </Button>
              {uploadedFile && (
                <Chip
                  label={uploadedFile.name}
                  onDelete={() => setUploadedFile(null)}
                  variant="outlined"
                  sx={{ marginTop: 1 }}
                />
              )}
            </div> */}
          </Accordion>
        </div>
        <br />
        <p style={{fontSize: "16px", fontWeight: "500"}}>Address Details</p>
        <Accordion
          defaultExpanded
          style={{ display: "block", marginTop: "20px" }}
          elevation={4}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          ></AccordionSummary>
          <AccordionDetails>
            {/* Input fields for address details can be added here */}
            <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "space-between",
              }}
            >
              <label style={{ fontSize: "16px", marginLeft: "40px" }}>
                Address:
              </label>
              <textarea
                style={{
                  maxWidth: "615px",
                  minWidth: "615px",
                  maxHeight: "150px",
                  marginRight: "40px",
                  marginBottom: "10px",
                  padding: "5px",
                  fontSize: "16px",
                  outline: "1px",
                }}
                readOnly={readOnly}
                value={project?.formatted_address}
                required
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "space-between",
              }}
            >
              <label style={{ fontSize: "16px", marginLeft: "40px" }}>
                State:
              </label>
              <Stack>
                <Autocomplete
                  sx={{
                    width: "615px",
                    marginRight: "40px",
                    marginBottom: "10px",
                  }}
                  options={states}
                  renderInput={(params) => <TextField {...params} required />}
                 readOnly={readOnly}
                 value={project?.state}
                  freeSolo
                />
              </Stack>
            </div>
          </AccordionDetails>
        </Accordion>
        {/* <Button
          className="btn"
          variant="contained"
          style={{ width: "100px", padding: "5px", backgroundColor: "#49BB43" }}
          type="submit"
          sx={{
            marginTop: 2,
            
           
          }}
        >
          Continue
        </Button> */}
      </div>
    </form>
  );
};

export default ViewProject;
