import React, { useState } from "react";
import GalleryAccordion from "./accordians/GalleryAccordian";
import ActualPhotosGalleryAccordion from "./accordians/GalleryForActualPhtosAccordian";
import AdditionalInfo from "./accordians/AdditionalInfo";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../../utils/api";
import {
  Backdrop,
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import axios, { AxiosResponse } from "axios";
import { url } from "../../../../../configs/config";

const UploadMedia = () => {
  const navigate = useNavigate();
  const [galleryPhotos, setGalleryPhotos] = useState<(File | null)[]>([]);
  const [autualPhotos, setActualPhotos] = useState<(File | null)[]>([]);
  const [broucherFile, setBroucherFile] = useState<File | null>(null);
  const [progressStatus, setProgressStatus] = useState<number>(0);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [ytVideoUrl, setYtVideoUrl] = useState<string | null>(null);

  const queryParams = new URLSearchParams(window.location.search);
  const projectId = queryParams.get("pid");

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("projectId", String(projectId));
      formData.append("yt_vdo_link", String(ytVideoUrl));
      if (broucherFile) {
        formData.append("brochures", broucherFile);
      }
      if (videoFile) {
        formData.append("videos", videoFile);
      }
      galleryPhotos.forEach((photo) => {
        if (photo) {
          formData.append("photos", photo);
        }
      });
      autualPhotos.forEach((photo) => {
        if (photo) {
          formData.append("actual_photos", photo);
        }
      });

      // console.log("Checking the arrays of phots-->", galleryPhotos);
      // const response = await api.project.uploadProjectMedia(formData);
      if (formData) {
        const response: AxiosResponse = await axios.patch(
          `${url}/api/v1/project/upload-project-media`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              setProgressStatus(
                Math.round(
                  (progressEvent.loaded / (progressEvent?.total || 1)) * 100
                )
              );
            },
          }
        );
        if (response) {
          const {
            data: { result },
          } = response;
          console.log("ADDED__RESPONSE---->", response);
          alert("Plot Uploaded successfully!");
          console.log("==>response", response);
          navigate(`/project-details?pid=${result?._id}`);
        }
      } else {
        throw new Error(`API request failed`);
      }
    } catch (error) {
      console.error("Error while adding", error);
      alert("Error while submitting data");
    } finally {
      setLoading(false);
    }
  };
  console.log("progress", progressStatus);
  const steps = ["Step 1", "Step 2", "Step 3"];

  return (
    <div>
      <div
        style={{
          width: "70%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 20,
        }}
      >
        <Stepper activeStep={2}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress
          color="primary"
          variant="determinate"
          size={40}
          value={progressStatus}
        />
      </Backdrop>
      <GalleryAccordion
        galleryPhotos={galleryPhotos}
        setGalleryPhotos={setGalleryPhotos}
        tittle="Upload Commercials Photos"
      />
      <GalleryAccordion
        galleryPhotos={autualPhotos}
        setGalleryPhotos={setActualPhotos}
        tittle="Upload Actual Photos (Optional)"
      />
      <AdditionalInfo
        broucherFile={broucherFile}
        setBroucherFile={setBroucherFile}
        setVideoFile={setVideoFile}
        videoFile={videoFile}
        ytVideoUrl={ytVideoUrl}
        setYtVideoUrl={setYtVideoUrl}
      />
      <Button
        className="btn"
        variant="contained"
        endIcon={<SendIcon />}
        style={{ width: "100px", padding: "5px", backgroundColor: "#49BB43" }}
        onClick={handleSubmit}
        sx={{
          marginTop: 2,
        }}
      >
        {loading ? "Loading..." : "Submit"}
      </Button>
    </div>
  );
};

export default UploadMedia;
