import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import Chip from "@mui/material/Chip";
import { VisuallyHiddenInput } from "./VisualInput";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const LocationPlanImageAccordian: React.FC<any> = ({
  locationHighlightsImage,
  setLocationHighlightsImage,
  locationPlanImage,
  setLocationPlanImage,
}) => {
  console.log("==>locationPlanImage", locationPlanImage);
  return (
    <Accordion defaultExpanded style={{ display: "block" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h3>Location Plan & Highlights Image</h3>
      </AccordionSummary>
      <AccordionDetails>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label className="labels" style={{ paddingLeft: 30 }}>
              Location Plan Image:
            </label>
            {!locationPlanImage ? (
              <Button
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  marginRight: 60,
                }}
                endIcon={<UploadOutlinedIcon />}
                className="btn"
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
              >
                Upload Location Plan Image
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(event: any) => {
                    const file = event.target.files?.[0];
                    if (file) {
                      setLocationPlanImage(file);
                    }
                  }}
                  required
                />
              </Button>
            ) : (
              <div style={{ position: 'relative', display: 'inline-block', marginTop: 20,marginRight:50 }}>
              <img
                src={URL.createObjectURL(locationPlanImage)}
                alt="Location Plan"
                style={{
                  width: 200, // adjust the size as needed
                  height: 150,
                  borderRadius: 8,
                  boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                }}
              />
              <IconButton
                onClick={() => setLocationPlanImage(null)}
                style={{
                  position: 'absolute',
                  top: -10,
                  right: -10,
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
                }}
                size="small"
              >
                <CloseIcon />
                
              </IconButton>
            </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label className="labels" style={{ paddingLeft: 30 }}>
              Location Highlights Image:
            </label>
            {!locationHighlightsImage ? (
              <Button
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  marginRight: 60,
                }}
                endIcon={<UploadOutlinedIcon />}
                className="btn"
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
              >
                Upload Location Highlights Image
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(event: any) => {
                    const file = event.target.files?.[0];
                    if (file) {
                      setLocationHighlightsImage(file);
                    }
                  }}
                  required
                />
              </Button>
            ) : (
              <div style={{ position: 'relative', display: 'inline-block', marginTop: 20,marginRight:50 }}>
              <img
                src={URL.createObjectURL(locationHighlightsImage)}
                alt="Location Plan"
                style={{
                  width: 200, // adjust the size as needed
                  height: 150,
                  borderRadius: 8,
                  boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                }}
              />
              <IconButton
                onClick={() => setLocationHighlightsImage(null)}
                style={{
                  position: 'absolute',
                  top: -10,
                  right: -10,
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
                }}
                size="small"
              >
                <CloseIcon />
                
              </IconButton>
            </div>
              // <Chip
              //   label={locationHighlightsImage.name}
              //   onDelete={() => setLocationHighlightsImage(null)}
              //   variant="outlined"
              //   sx={{ marginTop: 1, marginRight: 20 }}
                
              // />
            )}
          </div>
        </>
      </AccordionDetails>
    </Accordion>
  );
};

export default LocationPlanImageAccordian;
