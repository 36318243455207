// export const url = "http://localhost:8989";
export const url = "https://d3v59ux8h03abs.cloudfront.net";
export const port = "8989";
export const version = "v1";

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};
const ENV: "PROD" | "LOCAL" = "PROD";

const PROD_URL = "https://incorners.com";
const LOCAL_URL = "http://localhost:3000";

export const IFRAME_BASE = String(ENV) === "PROD" ? PROD_URL : LOCAL_URL;
