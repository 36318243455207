import HomePage from "../components/pages/home/HomePage";
import { RouteType } from "./config";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import DashboardIndex from "../components/pages/bookings/DashboardIndex";
import MapIcon from "@mui/icons-material/Map";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import BookIcon from "@mui/icons-material/Book";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import AllCustomersPage from "../components/pages/allCustomers/AllCustomersPage";
import BookingPageLayout from "../components/pages/bookings/BookingPageLayout";
// import NewEnquiryPage from "../components/pages/bookings/newEnquiry/NewEnquiryPage";
import CurrentBookingPage from "../components/pages/bookings/currentBooking/CurrentBookingPage";
import BookingHistoryPage from "../components/pages/bookings/bookingHistory/BookingHistoryPage";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import Builders from "../components/pages/builders/Builders";
import BuilderForm from "../components/pages/builders/builderForm/BuilderForm";
import PermissionPage from "../components/pages/permission/PermissionPage";
import TelecallerManagement from "../components/pages/permission/telecallerManagement/TelecallerManagement";
import BuilderManagement from "../components/pages/permission/advertisement/Advertisement";
import UserManagement from "../components/pages/permission/userManagement/UserManagement";
import UserForm from "../components/pages/permission/userManagement/userForm/UserForm";
import AdminPage from "../components/pages/admin/AdminPage";
import AdminPlots from "../components/pages/admin/plots/Plots";
import AddPlotsForm from "../components/pages/admin/plots/addPlots/AddPlotsForm";
import UploadLayout from "../components/pages/admin/plots/addPlots/UploadLayout";
import {
  AdminPanelSettingsRounded,
  People,
  ScatterPlotSharp,
  Settings,
} from "@mui/icons-material";
import NewEnquiry from "../components/pages/admin/enquiry/NewEnquiry";
// import AdminReports from "../components/pages/reports/AdminReports";
import Book from "@mui/icons-material/Book";
import EmployeeManagemnet from "../components/pages/admin/employeeManagment/EmployeeManagemnet";
import AddEmployee from "../components/pages/admin/employeeManagment/addEmployee/AddEmployee";
import ForwardedEnquiry from "../components/pages/admin/forwardedEnquiry/ForwardedEnquiry";
import AssignedProject from "../components/pages/admin/assignedProject/AssignedProject";
import ProjectDetails from "../components/pages/projects/projectDetails/ProjectDetails";
import EnquiryDetails from "../components/pages/admin/enquiry/enquiryDetails/EnquiryDetails";
import AddFollowUp from "../components/pages/admin/enquiry/enquiryDetails/followUpGrid/addFollowUp/AddFollowUp";
import Reports from "../components/pages/reports/Reports";

const marketerRoutes: RouteType[] = [
  {
    path: "marketer",
    element: <AdminPage />,
    state: "admin",
  },
  {
    path: "marketer/all-customers",
    element: <AllCustomersPage />,
    state: "allcustomers",
    sidebarProps: {
      displayText: "All Customers",
      icon: <People />,
    },
  },
  {
    path: "marketer/admin-enquiry",
    element: <NewEnquiry />,
    state: "customerEnquiry",
    sidebarProps: {
      displayText: "Customer Enquiry",
      icon: <LiveHelpOutlinedIcon />,
    },
  },
  {
    path: "marketer/employee",
    element: <EmployeeManagemnet />,
    state: "employee",
    sidebarProps: {
      displayText: "Employee Management",
      icon: <PeopleOutlinedIcon />,
    },
  },

  {
    path: "marketer/Plots",
    element: <AdminPlots />,
    state: "allPlots",
    sidebarProps: {
      displayText: "All Plots",
      icon: <MapIcon />,
    },
  },
  {
    path: "marketer/project-details",
    element: <ProjectDetails />,
    state: "plots",
  },
  {
    path: "marketer/enquiry-details",
    element: <EnquiryDetails mode="ENQUIRY" />,
    state: "plots",
  },
  {
    path: "marketer/forwarded-enquiry-details",
    element: <EnquiryDetails mode="FORWARDED-ENQUIRY" />,
    state: "plots",
  },
  {
    path: "/add-follow-up",
    element: <AddFollowUp />,
    state: "followup",
  },
  {
    path: "marketer/forwarded_enquiry",
    element: <ForwardedEnquiry />,
    state: "forwarded_enqury",
    sidebarProps: {
      displayText: "Forwared Enquiry",
      icon: <ArrowForwardOutlinedIcon />,
    },
  },
  {
    path: "marketer/assigned_project",
    element: <AssignedProject />,
    state: "assigned_projects",
    sidebarProps: {
      displayText: "Assigned Projects",
      icon: <AssignmentTurnedInOutlinedIcon />,
    },
  },

  {
    path: "marketer/add-plots",
    element: <AddPlotsForm />,
    state: "plots",
  },
  {
    path: "marketer/reports",
    element: <Reports />,
    state: "reports",

    sidebarProps: {
      displayText: "Reports",
      icon: <Book />,
    },
  },
  // {
  //   path: "marketer/select-plots",
  //   element: <UploadLayout />,
  //   state: "plots",
  // },
  {
    path: "marketer/add-employee",
    element: <AddEmployee />,
    state: "admin",
  },
];

export default marketerRoutes;
