import { Accordion, AccordionDetails, AccordionSummary, TextareaAutosize } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Notes = ({ note }: { note: string }) => {
  return (
    <Accordion
      defaultExpanded
      style={{ display: "block", marginTop: "20px" }}
      elevation={4}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >Message From Telecaller</AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "flex",
            alignItems: "left",
            justifyContent: "space-between",
          }}
        >
          <label style={{ fontSize: "16px", marginLeft: "40px" }}>
            Notes:
          </label>
          <textarea
                  style={{
                    maxWidth: "60%",
                    minWidth: "60%",
                    maxHeight: "150px",
                    marginRight: "40px",
                    marginBottom: "10px",
                    padding: "5px",
                    fontSize: "18px",
                    outline: "1px",
                  }}
                readOnly
                value={note}
                  required
                />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Notes;
