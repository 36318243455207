import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ILayout } from "../../../@types/interface/layout.interface";
import { IProject } from "../../../@types/interface/Projects";
import { ROLES } from "../../../constants/roles/Roles";
import { api } from "../../../utils/api";
import ProjctLayout from "../../shared/projectLayout/ProjectLayout";

const IframeView = () => {
  const { builderId, projectId } = useParams<{
    builderId: string;
    projectId: string;
  }>();

  const [project, setProject] = React.useState<IProject | null>(null);
  const [rectanglePositions, setRectanglePositions] = React.useState<ILayout[]>(
    []
  );

  const getProjectDetails = React.useCallback(async () => {
    const payload = {
      project_object_id: projectId,
      role: ROLES.builder_admin,
      admin_object_id: builderId,
    };
    const projectDetails = await api.project.getSelectiveProjectDetails(
      payload
    );
    console.log(projectDetails);
    if (projectDetails) {
      setProject(projectDetails.project_details);
      setRectanglePositions(projectDetails.layout_list);
    }
  }, [projectId, builderId]);

  useEffect(() => {
    getProjectDetails();
  }, [getProjectDetails]);

  return (
    <div>
      <ProjctLayout
        layoutPosition={rectanglePositions}
        mode="IFRAME"
        plotId={projectId || ""}
        project={project}
        key={0}
      />
    </div>
  );
};

export default IframeView;
