import React, { ChangeEvent } from "react";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";

interface FileUploadFieldsProps {
  label: string;
  file: File | null;
  setFile: (file: File | null) => void;
  onChange?: (file: File) => void;
  accept?: string; // Accept prop to specify accepted file types
}

const FileUploadFields: React.FC<FileUploadFieldsProps> = ({
  label,
  file,
  setFile,
  onChange,
  accept, // Accept prop
}) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
      if (onChange) {
        onChange(file);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <label className="labels" style={{ paddingLeft: 30 }}>
        {label}
      </label>
      <Button
        style={{
          marginTop: 20,
          marginBottom: 20,
          marginRight: 60,
        }}
        endIcon={<UploadOutlinedIcon />}
        className="btn"
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
      >
        Upload {label}
        <input
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          required
          accept={accept} // Setting accept attribute
        />
      </Button>
      {file && (
        <Chip
          label={file.name}
          onDelete={() => setFile(null)}
          variant="outlined"
          sx={{ marginTop: 1, marginRight: 20 }}
        />
      )}
    </div>
  );
};

export default FileUploadFields;
