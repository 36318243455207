import { Grid, IconButton, TextField } from "@mui/material";
import React from "react";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { IDescriptionProps } from "../../../../../../@types/props/overview.props";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const DescriptionOverview: React.FC<IDescriptionProps> = ({
  mode,
  project_overview,
  handleAddMore,
  handleChangeOverview,
  index,
  handleRemove
}) => {
  return (
    <Grid
      container
      spacing={2}
      alignItems={"center"}
      style={{ marginBottom: 10 }}
    >
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <TextField
          fullWidth
          id="outlined-basic"
          label={
            mode === "OVERVIEW"
              ? "Projet Overview"
              : mode === "LOCATION"
              ? "Location Highlights"
              : "Project Attraction"
          }
          name={
            mode === "OVERVIEW"
              ? "project_overview"
              : mode === "LOCATION"
              ? "location_highlight"
              : "project_highlight"
          }
          value={project_overview}
          onChange={(event) => handleChangeOverview(event, index)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
        <IconButton
          className="dark-green-button"
          style={{ marginRight: 4 }}
          onClick={handleAddMore}
        >
          <AddTaskIcon />
        </IconButton>
        <IconButton
          className="dark-red-button"
          style={{ marginRight: 4, color: "red" }}
          onClick={() => handleRemove(index)}
        >
          <DeleteOutlineIcon /> 
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default DescriptionOverview;
