import { Button } from "@mui/material";
import { IProject } from "../../../../../@types/interface/Projects";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../../../../contexts/authContext/authContext";
import { ROLES } from "../../../../../constants/roles/Roles";
import BuilderContext from "../../../../../contexts/builderContext/BuilderContext";
import { IassignedProject } from "../../../../../@types/interface/assignedProjectList";

const SeeDetailsCellRenderer = ({
  data,
}: {
  data: IProject & IassignedProject;
}) => {
  const location = useLocation();

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { builderDetails } = useContext(BuilderContext);

  const isOnAdminPage = location.pathname.startsWith("/admin");
  const isOnOwnerPage = location.pathname.startsWith("/landlord");
  const isOnRetailerPage = location.pathname.startsWith("/reseller");
  const isOnMarketerPage = location.pathname.startsWith("/marketer");

  const handleRouteToProjectDetails = () => {
    if (
      isOnAdminPage ||
      isOnOwnerPage ||
      isOnRetailerPage ||
      isOnMarketerPage
    ) {
      navigate(
        `/admin/project-details?cid=${builderDetails?._id}&pid=${
          user?.role === ROLES.project_manager ? data.project._id : data?._id
        }`
      );
    } else {
      navigate(`/phase-list?pid=${data._id}`);
    }
  };
  return (
    <Button
      variant="outlined"
      className="green-outlined-button"
      onClick={handleRouteToProjectDetails}
    >
      See Details
    </Button>
  );
};

export default SeeDetailsCellRenderer;
