import { Box, Button, Modal, TextField } from "@mui/material";
import React, { useState } from "react";
import { api } from "../../../../../utils/api";
import { createTheme, ThemeProvider } from "@mui/material/styles";

interface UpcomingProjectModalProps {
  open: boolean;
  onClose: () => void;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#55902f",
    },
  },
});

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UpcomingProjectModal: React.FC<UpcomingProjectModalProps> = ({
  open,
  onClose,
}) => {
  const [address, setAddress] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [details, setDetails] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setImage(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("address", address);
    formData.append("location", location);
    formData.append("details", details);
    if (image) {
      formData.append("upcomingProject_image", image);
    }

    try {
      await api.upcomingProjects.addUpcomingProject(formData);
      // You can add a callback to refresh the list or show a success message
      onClose();
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={open} onClose={onClose}>
        <Box sx={{ ...modalStyle }}>
          <h2>Add Upcoming Project</h2>
          <TextField
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Details"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" component="label">
            Upload Image
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default UpcomingProjectModal;
