import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Transport from "../transport/Transport";
import { ITransport } from "../../../../../../@types/interface/transport.interface";
import { ITransportAccordianProps } from "../../../../../../@types/props/TransportAccordian.props";
import { defaultNearbyForm } from "../../../../../../defaultValue/projectForm/ProjectForm";

const TransportsAccordian: React.FC<ITransportAccordianProps> = ({
  transports,
  setTransports,
}) => {
  const handleSaveLandMark = (nearby: ITransport, index: number) => {
    const previousNearby = [...transports];
    previousNearby[index] = nearby;
    setTransports(previousNearby);
  };

  const handleAddMore = () => {
    const lastNearby = transports[transports.length - 1];
    if (
      lastNearby.transport_type === "" ||
      lastNearby.distance === 0 ||
      lastNearby.transport_name === ""
    ) {
      alert("Please fill the previous nearby details");
    } else {
      setTransports([...transports, defaultNearbyForm]);
    }
  };

  const handleChangeNearby = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    index: number
  ) => {
    const { name, value } = event.target;
    console.log("===>event", event);
    const previousNearby = [...transports];
    previousNearby[index] = {
      ...previousNearby[index],
      [name]: value,
    };
    setTransports(previousNearby);
  };

  const handleRemove = (index: number) => {
    if (transports.length === 1) {
      alert("Cannot remove the last item. Please add more items before removing.");
    } else {
      const updatedFacility = transports.filter((_, i) => i !== index);
      setTransports(updatedFacility);
    }
  };
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
          Nearby Highlights
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {transports.map((transport: ITransport, index: number) => {
          return (
            <Transport
              transport={transport}
              key={index}
              index={index}
              handleAddMore={handleAddMore}
              handleSaveLandMark={handleSaveLandMark}
              handleChangeNearby={handleChangeNearby} handleRemove={handleRemove}            />
          );
        })}
        {/* <Transport/> */}
      </AccordionDetails>
    </Accordion>
  );
};

export default TransportsAccordian;
