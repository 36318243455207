import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import OTPImage from "./otp-image.svg"; // Import your OTP image
import { styled } from "@mui/material/styles";

// Styled Box component for rounded corners
const StyledBox = styled(Box)({
  borderRadius: "10px",
  padding: "24px",
  background: "#fff",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
});

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  outline: "none", // Remove default modal outline
};

interface ConfirmModalProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  originalOTP: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  handleClose,
  handleConfirm,
  originalOTP,
}) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputsRef = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    // Focus on the first input field when the modal opens
    if (open) {
      inputsRef.current[0]?.focus();
    }
  }, [open]);

  //   const handleChange = (
  //     index: number,
  //     event: ChangeEvent<HTMLInputElement>
  //   ) => {
  //     const { value } = event.target as HTMLInputElement;
  //     if (value.length <= 1) {
  //       const newOtp = [...otp];
  //       newOtp[index] = value;
  //       setOtp(newOtp);

  //       // Move focus to the next input field if a digit is entered
  //       if (index < 3 && value !== "") {
  //         inputsRef.current[index + 1]?.focus();
  //       }
  //     }
  //   };

  const handleChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target as HTMLInputElement;
    const newOtp = [...otp];

    if (value.length === 1) {
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input field if a digit is entered
      if (index < 3) {
        inputsRef.current[index + 1]?.focus();
      }
    } else if (value.length === 0) {
      // Move focus to the previous input field if backspace/delete is pressed
      newOtp[index] = value;
      setOtp(newOtp);
      if (index > 0) {
        inputsRef.current[index - 1]?.focus();
      }
    }
  };

  const handleOtpSubmit = () => {
    if (otp.join("") === originalOTP) {
      handleConfirm();
    } else {
      alert("Incorrect OTP");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledBox sx={style}>
        <LockOutlinedIcon sx={{ fontSize: 40, mb: 2 }} />
        <Typography variant="h6" component="h2" gutterBottom>
          Enter OTP
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please enter the 4-digit OTP sent to your phone.
        </Typography>
        <img
          src="https://img.freepik.com/free-vector/privacy-policy-concept-illustration_114360-7853.jpg?t=st=1717102887~exp=1717106487~hmac=aaf439b9957ce5e05f65556cdb855e7668bdaeef8f7109319636c98c6472a6d4&w=1480"
          alt=""
          style={{ width: "80%", marginBottom: "16px" }}
        />
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          {otp.map((_, index) => (
            <TextField
              key={index}
              variant="outlined"
              value={otp[index]}
              onChange={(event) =>
                handleChange(index, event as ChangeEvent<HTMLInputElement>)
              }
              inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
              sx={{ width: "3rem", marginRight: 1 }}
              inputRef={(input) => (inputsRef.current[index] = input)}
            />
          ))}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOtpSubmit}
          sx={{ mt: 2 }}
        >
          Confirm
        </Button>
      </StyledBox>
    </Modal>
  );
};

export default ConfirmModal;

// import React, { useState, ChangeEvent } from "react";
// import Modal from "@mui/material/Modal";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";

// const style = {
//   position: "absolute" as const,
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
// };

// interface ConfirmModalProps {
//   open: boolean;
//   handleClose: () => void;
//   handleConfirm: () => void;
//   originalOTP: string;
// }

// const ConfirmModal: React.FC<ConfirmModalProps> = ({
//   open,
//   handleClose,
//   handleConfirm,
//   originalOTP,
// }) => {
//   const [otp, setOtp] = useState(["", "", "", ""]);

//   const handleChange = (
//     index: number,
//     event: ChangeEvent<HTMLInputElement>
//   ) => {
//     const { value } = event.target as HTMLInputElement;
//     if (value.length <= 1) {
//       const newOtp = [...otp];
//       newOtp[index] = value;
//       setOtp(newOtp);
//     }
//   };

//   const handleOtpSubmit = () => {
//     if (otp.join("") === originalOTP) {
//       handleConfirm();
//     } else {
//       alert("Incorrect OTP");
//     }
//   };

//   return (
//     <Modal
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="modal-modal-title"
//       aria-describedby="modal-modal-description"
//     >
//       <Box sx={style}>
//         <Typography id="modal-modal-title" variant="h6" component="h2">
//           Enter OTP
//         </Typography>
//         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
//           Please enter the 4-digit OTP sent to your email.
//         </Typography>
//         <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
//           {otp.map((_, index) => (
//             <TextField
//               key={index}
//               variant="outlined"
//               value={otp[index]}
//               onChange={(event) =>
//                 handleChange(index, event as ChangeEvent<HTMLInputElement>)
//               } // Cast event here
//               inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
//               sx={{ width: "3rem", marginRight: 1 }}
//             />
//           ))}
//         </Box>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleOtpSubmit}
//           sx={{ mt: 2 }}
//         >
//           Confirm
//         </Button>
//       </Box>
//     </Modal>
//   );
// };

// export default ConfirmModal;
