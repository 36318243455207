import { Paper } from "@mui/material";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const PlotAccessibility = () => {
  return (
    <Paper elevation={3} style={{ padding: 14, marginTop: 15 }}>
      <h2>Plot Accessibility</h2>

      <FormGroup style={{ paddingLeft: 20 }}>
        <FormControlLabel control={<Checkbox />} label="Tooltip Visible" />
        <FormControlLabel control={<Checkbox />} label="Availability Visible" />
        <FormControlLabel
          control={<Checkbox />}
          label="Plot Detection Visible"
        />
      </FormGroup>
    </Paper>
  );
};

export default PlotAccessibility;
