import { MESSAGE } from "../../../constants/api/message";
import { request } from "../api";
import { headers } from "../../../configs/config";
const { post, del } = request;

const initialRoute = "project";

export const deleteProjectFacility = async (payload: any) => {
  try {
    console.log("payload", payload);
    const endpoint = `${initialRoute}/delete-project-facility?facility_id=${payload}`;
    const response = await del(endpoint, { ...headers });
    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.delete.succ) {
        const {
          data: { result },
        } = response;
        return result;
      }
    }
    throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};
