import HomePage from "../components/pages/home/HomePage";
import { RouteType } from "./config";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import DashboardIndex from "../components/pages/bookings/DashboardIndex";
import ConnectedTvOutlinedIcon from "@mui/icons-material/ConnectedTvOutlined";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import MapIcon from "@mui/icons-material/Map";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AllCustomersPage from "../components/pages/allCustomers/AllCustomersPage";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import Builders from "../components/pages/builders/Builders";
import BuilderForm from "../components/pages/builders/builderForm/BuilderForm";
import PermissionPage from "../components/pages/permission/PermissionPage";
import TelecallerManagement from "../components/pages/permission/telecallerManagement/TelecallerManagement";
import BuilderManagement from "../components/pages/permission/advertisement/Advertisement";
import UserManagement from "../components/pages/permission/userManagement/UserManagement";
import UserForm from "../components/pages/permission/userManagement/userForm/UserForm";
import {
  Book,
  Construction,
  People,
  PermIdentity,
  ScatterPlotSharp,
  Settings,
  SettingsAccessibility,
  SettingsAccessibilityRounded,
} from "@mui/icons-material";
import PlotsPage from "../components/pages/projects/ProjectList";
import AddPlotsForm from "../components/pages/admin/plots/addPlots/AddPlotsForm";
import Reports from "../components/pages/reports/Reports";
import Advertisement from "../components/pages/permission/advertisement/Advertisement";
import AddAdvertisement from "../components/pages/permission/advertisement/addBanner/AddBanner";
import AddBanner from "../components/pages/permission/advertisement/addBanner/AddBanner";
import ProjectDetails from "../components/pages/projects/projectDetails/ProjectDetails";
import AddReport from "../components/pages/reports/addReport/ReportForm";
import CityReport from "../components/pages/reports/cityReport/CityReport";
import BuilderReport from "../components/pages/reports/builderReport/BuilderReport";
import ProjectReport from "../components/pages/reports/projectReport/ProjectReport";
import QuizIcon from "@mui/icons-material/Quiz";
import ResellerEnquiry from "../components/pages/resellerEnquiry/ResellerEnquiry";
import BankDetails from "../components/pages/permission/bankDetails/BankDetails";
import AddBank from "../components/pages/permission/bankDetails/addBank/AddBank";
import UploadMedia from "../components/pages/admin/plots/addPlots/UploadMedia";
import PhaseList from "../components/pages/projects/phaseList/PhaseList";

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <HomePage />,
    state: "home",
  },

  {
    path: "/all-customers",
    element: <AllCustomersPage />,
    state: "allcustomers",
    sidebarProps: {
      displayText: "All Customers",
      icon: <People />,
    },
  },
  {
    path: "/builders",
    element: <Builders />,
    state: "builders",
    sidebarProps: {
      displayText: "Builders",
      icon: <BusinessOutlinedIcon />,
    },
  },
  {
    path: "/add-builders",
    element: <BuilderForm />,
    state: "builders",
  },
  {
    path: "/add-banner",
    element: <AddBanner />,
    state: "advertisement",
  },
  {
    path: "/plots",
    element: <PlotsPage />,
    state: "plots",
    sidebarProps: {
      displayText: "All Plots",
      icon: <MapIcon />,
    },
  },
  {
    path: "/add-plots",
    element: <AddPlotsForm />,
    state: "plots",
  },
  {
    path: "/add-phase",
    element: <AddPlotsForm />,
    state: "plots",
  },
  {
    path: "/upload-project-media",
    element: <UploadMedia />,
    state: "uploadMedia",
  },
  {
    path: "/project-details",
    element: <ProjectDetails />,
    state: "plots",
  },
  {
    path: "/phase-list",
    element: <PhaseList />,
    state: "phase",
  },
  {
    path: "/enquiry",
    element: <ResellerEnquiry />,
    state: "resller",
    sidebarProps: {
      displayText: "Reseller Enquiry",
      icon: <QuizIcon />,
    },
  },
  {
    path: "/add-reports",
    element: <AddReport />,
    state: "reports",
  },
  {
    path: "/reports/city-reports",
    element: <CityReport />,
    state: "reports",
  },
  {
    path: "/reports/builder-reports",
    element: <BuilderReport />,
    state: "reports",
  },
  {
    path: "/reports/project-reports",
    element: <ProjectReport />,
    state: "reports",
  },
  {
    path: "/reports",
    element: <Reports />,
    state: "reports",

    sidebarProps: {
      displayText: "Reports",
      icon: <AssessmentOutlinedIcon />,
    },
  },

  {
    path: "/permission",
    element: <PermissionPage />,
    state: "permission",
    sidebarProps: {
      displayText: "Settings",
      icon: <Settings />,
    },
    child: [
      {
        index: true,
        element: <DashboardIndex />,
        state: "bookings.index",
      },
      {
        path: "/permission/user-management",
        element: <UserManagement />,
        state: "bookings.currentbooking",
        sidebarProps: {
          displayText: "User Management",
          icon: <SupervisedUserCircleIcon />,
        },
      },

      {
        path: "/permission/add-user",
        element: <UserForm />,
        state: "permission",
      },
      {
        path: "/permission/advertisement",
        element: <Advertisement />,
        state: "advertisement",
        sidebarProps: {
          displayText: "Advertisement",
          icon: <ConnectedTvOutlinedIcon />,
        },
      },
      {
        path: "/permission/telecaller-management",
        element: <TelecallerManagement />,
        state: "telecaller",
        sidebarProps: {
          displayText: "Telecaller Management",
          icon: <LocalPhoneOutlinedIcon />,
        },
      },
      {
        path: "/permission/bank-details",
        element: <BankDetails />,
        state: "bank",
        sidebarProps: {
          displayText: "Bank",
          icon: <AccountBalanceIcon />,
        },
      },
      {
        path: "/permission/add-bank",
        element: <AddBank />,
        state: "permission",
      },
    ],
  },
];

export default appRoutes;
