import React, { useCallback, useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import DataGrid from "../../../shared/dataGrid/DataGrid";
import { IEnquiryIframe } from "../../../../@types/interface/enquiryIframe.interface";
import { EnquiryIframeColDefs } from "./enquiryIframeColdefs/enquiryIframeColdefs";
import { IPagination } from "../../../../@types/interface/pagination";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import { api } from "../../../../utils/api";

const EnquiryIframe = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [enquiryIframeDetails, setEnquiryIframeDetails] = useState<
    IEnquiryIframe[]
  >([]);
  const queryParams = new URLSearchParams(window.location.search);
  const builderId = queryParams.get("cid");
  const [enquiryIframePagination, setEnquiryIframePagination] =
    useState<IPagination>({
      currentPage: 1,
      pageCount: 1,
    });

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setEnquiryIframePagination((prev) => ({
      ...prev,
      currentPage: value,
    }));
  };

  const fetchEnquiryIframe = useCallback(async () => {
    try {
      const filter = {
        page: enquiryIframePagination.currentPage,
        builder_object_id: builderId,
      };
      const response = await api.iframe.getEnquiryIframe(filter);
      if (response) {
        setEnquiryIframeDetails(response.result);
        setEnquiryIframePagination(response.pagination);
       
      }
    } catch (error) {
      console.error("error:", error);
      
    }
  }, [builderId, enquiryIframePagination.currentPage]);

  useEffect(() => {
    fetchEnquiryIframe();
  }, [fetchEnquiryIframe]);

  useEffect(() => {
    setDashboardHeader("Enquiry Iframe");
  }, [setDashboardHeader]);
  return (
    <div>
      <DataGrid rowData={enquiryIframeDetails} colDefs={EnquiryIframeColDefs} />
      <BasicPagination
        currentPage={enquiryIframePagination.currentPage}
        pageCount={enquiryIframePagination.pageCount}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default EnquiryIframe;
