import { RouteType } from "./config";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import MapIcon from "@mui/icons-material/Map";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AllCustomersPage from "../components/pages/allCustomers/AllCustomersPage";
import AdminPage from "../components/pages/admin/AdminPage";
import AdminPlots from "../components/pages/admin/plots/Plots";
import { People } from "@mui/icons-material";
import NewEnquiry from "../components/pages/admin/enquiry/NewEnquiry";

import Book from "@mui/icons-material/Book";
import EmployeeManagemnet from "../components/pages/admin/employeeManagment/EmployeeManagemnet";
import AddEmployee from "../components/pages/admin/employeeManagment/addEmployee/AddEmployee";
import ForwardedEnquiry from "../components/pages/admin/forwardedEnquiry/ForwardedEnquiry";
import AssignedProject from "../components/pages/admin/assignedProject/AssignedProject";
import ProjectDetails from "../components/pages/projects/projectDetails/ProjectDetails";
import EnquiryDetails from "../components/pages/admin/enquiry/enquiryDetails/EnquiryDetails";
import AddFollowUp from "../components/pages/admin/enquiry/enquiryDetails/followUpGrid/addFollowUp/AddFollowUp";
import FollowUpCalendar from "../components/pages/admin/followUpCalender/FollowUpCalender";
import Reports from "../components/pages/reports/Reports";
import AddReport from "../components/pages/reports/addReport/ReportForm";
import LeadStatusDetails from "../constants/enquiry/leadStatus/leadStatusDetails/LeadStatusDetails";
import Viewlayout from "../components/pages/admin/viewLayout/Viewlayout";
import EnquiryIframe from "../components/pages/admin/enquiryIframe/EnquiryIframe";

const adminRoutes: RouteType[] = [
  {
    path: "/admin",
    element: <AdminPage />,
    state: "admin",
  },
  {
    path: "/admin/all-customers",
    element: <AllCustomersPage />,
    state: "allcustomers",
    sidebarProps: {
      displayText: "All Customers",
      icon: <People />,
    },
  },
  {
    path: "/admin/admin-enquiry",
    element: <NewEnquiry />,
    state: "customerEnquiry",
    sidebarProps: {
      displayText: "Customer Enquiry",
      icon: <LiveHelpOutlinedIcon />,
    },
  },
  {
    path: "/admin/enquiry-iframe",
    element: <EnquiryIframe />,
    state: "enquiryIframe",
    sidebarProps: {
      displayText: "Enquiry Iframe",
      icon: <LiveHelpOutlinedIcon />,
    },
  },
  {
    path: "/admin/employee",
    element: <EmployeeManagemnet />,
    state: "employee",
    sidebarProps: {
      displayText: "Employee Management",
      icon: <PeopleOutlinedIcon />,
    },
  },

  {
    path: "/admin/Plots",
    element: <AdminPlots />,
    state: "allPlots",
    sidebarProps: {
      displayText: "All Plots",
      icon: <MapIcon />,
    },
  },
  {
    path: "/admin/project-details",
    element: <Viewlayout />,
    state: "plots",
  },
  {
    path: "/admin/lead-status-details",
    element: <LeadStatusDetails />,
    state: "enquiry",
  },
  {
    path: "/admin/enquiry-details",
    element: <EnquiryDetails mode="ENQUIRY" />,
    state: "plots",
  },
  {
    path: "/admin/forwarded-enquiry-details",
    element: <EnquiryDetails mode="FORWARDED-ENQUIRY" />,
    state: "plots",
  },
  {
    path: "/admin/add-follow-up",
    element: <AddFollowUp />,
    state: "followup",
  },
  {
    path: "/admin/add-reports",
    element: <AddReport />,
    state: "reports",
  },
  {
    path: "/admin/forwarded_enquiry",
    element: <ForwardedEnquiry />,
    state: "forwarded_enqury",
    sidebarProps: {
      displayText: "Forwared Enquiry",
      icon: <ArrowForwardOutlinedIcon />,
    },
  },
  {
    path: "/admin/assigned_project",
    element: <AssignedProject />,
    state: "assigned_projects",
    sidebarProps: {
      displayText: "Assigned Projects",
      icon: <AssignmentTurnedInOutlinedIcon />,
    },
  },

  // {
  //   path: "/admin/add-plots",
  //   element: <AddPlotsForm />,
  //   state: "plots",
  // },
  {
    path: "/admin/reports",
    element: <Reports />,
    state: "reports",

    sidebarProps: {
      displayText: "Reports",
      icon: <Book />,
    },
  },
  {
    path: "/admin/follow-up",
    element: <FollowUpCalendar />,
    state: "followup",

    sidebarProps: {
      displayText: "Follow up",
      icon: <CalendarMonthIcon />,
    },
  },
  // {
  //   path: "/admin/select-plots",
  //   element: <UploadLayout />,
  //   state: "plots",
  // },
  {
    path: "/admin/add-employee",
    element: <AddEmployee />,
    state: "admin",
  },
];

export default adminRoutes;
