import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#fff2e6",
    color: "#001633",
    hoverBg: "#ffc999",
    activeBg: "#ffd7b3",
    border: " #808080",
  },
  adminSidebar: {
    bg: "#001a00",
    color: "#fff",
    hoverBg: "#004d00",
    activeBg: "#003300",
  },
  topbar: {
    bg: "#fff",
    color: "#000",
  },
  mainBg: colors.grey["100"],
};

export default colorConfigs;
