import { Button } from "@mui/material";
import { IProject } from "../../../../../@types/interface/Projects";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../../../../contexts/authContext/authContext";
import { ROLES } from "../../../../../constants/roles/Roles";
import BuilderContext from "../../../../../contexts/builderContext/BuilderContext";
import { ENQUIRY_STATUS } from "../../../../../constants/enquiryStatus/EnquiryStatus";
import { IEnquiry } from "../../../../../@types/interface/forwardedEnquiry.interface";
import { IBooking } from "../../../../../@types/interface/booking.interface";

const EnquiryDetailsCellRenderer = ({ data }: { data: any }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { builderDetails } = useContext(BuilderContext);
  const handleRouteToEnquiryDetails = () => {
    console.log("===>enq_st", data.enqury_status);
    if (data.enqury_status === ENQUIRY_STATUS.enquiry) {
      navigate(
        `/admin/enquiry-details?cid=${builderDetails?._id}&eid=${data?._id}`
      );
    } else {
      navigate(
        `/admin/forwarded-enquiry-details?cid=${builderDetails?._id}&eid=${user?.role === ROLES.project_manager ? data.book._id : data._id}`
      );
    }
  };
  return (
    <Button
      variant="outlined"
      className="green-outlined-button"
      onClick={handleRouteToEnquiryDetails}
    >
      See Details
    </Button>
  );
};

export default EnquiryDetailsCellRenderer;
