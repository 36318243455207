import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../utils/api";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";

import { Table } from "./userTable/Table";

import { useCallback, useContext, useEffect, useState } from "react";
import BuilderContext from "../../../../contexts/builderContext/BuilderContext";
import { AgGridReact } from "ag-grid-react";
import { AgCheckbox } from "ag-grid-community";
import UIContext from "../../../../contexts/uiContext/UIContext";
import "./user.css";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import Download from "../../../shared/downloadbtn/Download";
import DataGrid from "../../../shared/dataGrid/DataGrid";
import { AdminColDefs } from "./adminColDefs/adminColDefs";
import { IPagination } from "../../../../@types/interface/pagination";
import { IUser } from "../../../../@types/interface/admin.interface";

const result_per_page = 5;


const UserManagement = () => {
  const navigate = useNavigate();
  const handleNavigateToAddUser = () => {
    navigate("/permission/add-user");
  };
  const { setDashboardHeader } = useContext(UIContext);
  const { builderDetails } = useContext(BuilderContext);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);
  const [adminDetails, setAdminDetails] = useState<IUser[]>([]);

 

 

  const handleAdminPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const fetchAdmins = useCallback(async (filterQuery: any) => {
    try {
      const filter = {
        ...filterQuery,
        page: currentPage,
      };
      const response = await api.admin.getAdmin(filter);
      if (response) {
        setAdminDetails(response.result);
        const totalResults = response.pagination.total;
          const updatedTotalPages = Math.ceil(totalResults / result_per_page);
          setTotalPageCount(updatedTotalPages);
          setCurrentPage(response.pagination.currentPage);
        console.log("response", response.pagination);
      }
    } catch(error){
      console.error("error:", error);
      
    }
  } , [currentPage]) 
  
  

 

  useEffect(() => {
    setDashboardHeader("User Management");
  }, [setDashboardHeader]);

  useEffect(() => {
    fetchAdmins({});
  }, [fetchAdmins]);
  return (
    <div>
      <div className="add-user-btn">
        <Download />
        <Button
          className="btn"
          variant="contained"
          endIcon={<PersonAddOutlinedIcon />}
          onClick={handleNavigateToAddUser}
        >
          Add Admin
        </Button>
      </div>
      <DataGrid rowData={adminDetails} colDefs={AdminColDefs}/>
     <BasicPagination currentPage={currentPage} handlePageChange={handleAdminPageChange} pageCount={totalPageCount}/>
    </div>
  );
};

export default UserManagement;
