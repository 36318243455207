export const STATE_AUTHORITIES = [
  {
    state: "Andhra Pradesh",
    authorities: [
      "Andhra Pradesh Capital Region Development Authority (APCRDA)",
      "Andhra Pradesh Directorate of Town and Country Planning (APDTCP)",
    ],
  },
  {
    state: "Arunachal Pradesh",
    authorities: ["Town Planning Department (TPD)"],
  },
  {
    state: "Assam",
    authorities: ["Guwahati Metropolitan Development Authority (GMDA)"],
  },
  {
    state: "Bihar",
    authorities: [
      "Bihar State Housing Board (BSHB)",
      "Bihar Real Estate Regulatory Authority (BRERA)",
    ],
  },
  {
    state: "Chhattisgarh",
    authorities: [
      "Chhattisgarh Housing Board (CHB)",
      "Chhattisgarh Real Estate Regulatory Authority (CREERA)",
    ],
  },
  {
    state: "Goa",
    authorities: [
      "Goa Town and Country Planning Department (GTCPD)",
      "Goa Real Estate Regulatory Authority (GRERA)",
    ],
  },
  {
    state: "Gujarat",
    authorities: [
      "Ahmedabad Urban Development Authority (AUDA)",
      "Gandhinagar Urban Development Authority (GUDA)",
      "Gujarat Real Estate Regulatory Authority (GRERA)",
    ],
  },
  {
    state: "Haryana",
    authorities: [
      "Haryana Urban Development Authority (HUDA)",
      "Haryana Real Estate Regulatory Authority (HARYERA)",
    ],
  },
  {
    state: "Himachal Pradesh",
    authorities: [
      "Town and Country Planning Department (TCPD)",
      "Himachal Pradesh Real Estate Regulatory Authority (HPERA)",
    ],
  },
  {
    state: "Jharkhand",
    authorities: [
      "Jharkhand Urban Development and Housing Department (JUDHD)",
      "Jharkhand Real Estate Regulatory Authority (JHRERA)",
    ],
  },
  {
    state: "Karnataka",
    authorities: [
      "Bangalore Development Authority (BDA)",
      "Bengaluru Mahanagara Palike (BMP)",
      "Karnataka Real Estate Regulatory Authority (KARERA)",
    ],
  },
  {
    state: "Kerala",
    authorities: [
      "Thiruvananthapuram Development Authority (TDA)",
      "Kochi Metropolitan Development Authority (KMDA)",
      "Kerala Real Estate Development Authority (KRED)",
    ],
  },
  {
    state: "Madhya Pradesh",
    authorities: [
      "Madhya Pradesh Directorate of Town and Country Planning (MPDTCP)",
      "Madhya Pradesh Real Estate Regulatory Authority (MPRERA)",
    ],
  },
  {
    state: "Maharashtra",
    authorities: [
      "Maharashtra Housing and Area Development Authority (MHADA)",
      "Mumbai Metropolitan Region Development Authority (MMRDA)",
      "Maharashtra Real Estate Regulatory Authority (MHERA)",
    ],
  },
  {
    state: "Manipur",
    authorities: ["Manipur Urban Development Agency (MUDA)"],
  },
  {
    state: "Meghalaya",
    authorities: ["Meghalaya Urban Development Authority (MUDA)"],
  },
  {
    state: "Mizoram",
    authorities: [
      "Mizoram Urban Development and Poverty Alleviation Department (MUDPADD)",
    ],
  },
  {
    state: "Nagaland",
    authorities: ["Nagaland Town Planning and Urban Development (NTPUD)"],
  },
  {
    state: "Odisha",
    authorities: [
      "Bhubaneswar Development Authority (BDA)",
      "Odisha Real Estate Regulatory Authority (ODRERA)",
    ],
  },
  {
    state: "Punjab",
    authorities: [
      "Punjab Urban Planning and Development Authority (PUPDA)",
      "Punjab Real Estate Regulatory Authority (PRERA)",
    ],
  },
  {
    state: "Rajasthan",
    authorities: [
      "Joint Development Agreement (JDA)",
      "Rajasthan Real Estate Regulatory Authority (RERA)",
    ],
  },
  {
    state: "Sikkim",
    authorities: ["Sikkim Urban Development and Housing Department (SUDHD)"],
  },
  {
    state: "Tamil Nadu",
    authorities: [
      "Chennai Metropolitan Development Authority (CMDA)",
      "Tamil Nadu Real Estate Regulatory Authority (TNRERA)",
    ],
  },
  {
    state: "Telangana",
    authorities: [
      "Hyderabad Metropolitan Development Authority (HMDA)",
      "Department of Town and Country Planning (DTCP)",
      "Telangana State Real Estate Regulatory Authority (TSRERA)",
    ],
  },
  {
    state: "Tripura",
    authorities: ["Tripura Urban Planning and Development Authority (TUPDA)"],
  },
  {
    state: "Uttar Pradesh",
    authorities: [
      "Greater Noida Industrial Development Authority (GNIDA)",
      "Yamuna Expressway Industrial Development Authority (YEIDA)",
      "Uttar Pradesh Real Estate Regulatory Authority (UPRERA)",
    ],
  },
  {
    state: "Uttarakhand",
    authorities: [
      "Mussoorie Dehradun Development Authority (MDDA)",
      "Uttarakhand Real Estate Regulatory Authority (UKRERA)",
    ],
  },
  {
    state: "West Bengal",
    authorities: [
      "Kolkata Municipality Development Area (KMDA)",
      "West Bengal Housing Infrastructure Development Corporation Limited (WBHIDCL)",
      "Housing Industry Regulation Act (HIRA)",
    ],
  },
  {
    state: "Andaman and Nicobar Islands",
    authorities: [
      "Andaman and Nicobar Administration, Urban Development Department (ANAUDD)",
    ],
  },
  {
    state: "Chandigarh",
    authorities: ["Chandigarh Housing Board (CHB)"],
  },
  {
    state: "Dadra and Nagar Haveli and Daman and Diu",
    authorities: ["Town and Country Planning Department (TCPD)"],
  },
  {
    state: "Lakshadweep",
    authorities: ["Lakshadweep Public Works Department (LPWD)"],
  },
  {
    state: "Delhi",
    authorities: [
      "Delhi Development Authority (DDA)",
      "Delhi Real Estate Regulatory Authority (DREERA)",
    ],
  },
  {
    state: "Puducherry",
    authorities: ["Puducherry Planning Authority (PPA)"],
  },
];
