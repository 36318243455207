import { ColDef } from "ag-grid-community";
import DataCellRenderer from "../../components/shared/cellRenderer/DataCellRenderer";
import DateCellRenderer from "../../components/shared/cellRenderer/DateCellRenderer";

export const ResellerEnquiryColDefs: ColDef[] = [
  {
    field: "full_name",
    headerName: "Full Name",
    suppressSizeToFit: true,
    cellRenderer: DataCellRenderer,
  },
  {
    field: "phone",
    headerName: "Phone",
    suppressSizeToFit: true,
    cellRenderer: DataCellRenderer,
  },
  {
    field: "city",
    headerName: "City",
    suppressSizeToFit: true,
    cellRenderer: DataCellRenderer,
  },
  {
    field: "property_type",
    headerName: "Property Type",
    suppressSizeToFit: true,
    cellRenderer: DataCellRenderer,
  },
  {
    field: "additional_note",
    headerName: "Additional Note",
    suppressSizeToFit: true,
    cellRenderer: DataCellRenderer,
  },
  {
    field: "createdAt",
    headerName: "Enquiry Date",
    suppressSizeToFit: true,
    cellRenderer: DateCellRenderer,
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    suppressSizeToFit: true,
    cellRenderer: DateCellRenderer,
  },
  {
    field: "status",
    headerName: "Status",
    suppressSizeToFit: true,
    cellRenderer: DataCellRenderer,
  },
];
