import React, { useEffect, useRef, useState } from "react";
import { IProjectLayout } from "../../../@types/props/layoutProps.types";
import "./Layout.css";
import { Button, IconButton, Stack } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RestoreIcon from "@mui/icons-material/Restore";
import CropFreeIcon from "@mui/icons-material/CropFree";

import { ILayout } from "../../../@types/interface/layout.interface";
import ToolTip from "./toolTip/ToolTip";
import IFrameModal from "./iframeModal/IFrameModal";
import { IFRAME_BASE } from "../../../configs/config";

const ProjctLayout: React.FC<IProjectLayout> = ({
  project,
  layoutPosition,
  mode,
  plotId,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const builderId = queryParams.get("cid");
  const [selectedPositions, setSelectedPositions] = useState<ILayout[]>([]);
  const [originalImageWidth, setOriginalImageWidth] = useState(0);
  const [originalImageHeight, setOriginalImageHeight] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [showPlotdet, setShowPlotdet] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState<ILayout | null>(null);
  const [iframeDialogOpen, setIframeDialogOpen] = useState<boolean>(false);
  const [selectedPointIndex, setSelectedPointIndex] = useState<number | null>(
    null
  );
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });

  const containerRef = useRef(null);

  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1); // Limit max zoom level
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 1)); // Limit min zoom level
  };

  const resetPosition = () => {
    setZoomLevel(1);
  };

  const handleCloseToolTip = () => {
    setShowPlotdet(false);
  };

  const handleDialogToogle = () => {
    setIframeDialogOpen(!iframeDialogOpen);
  };

  const handleSelect = (event: any, position: ILayout, index: number) => {
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();
    const alreadySelected = selectedPositions.some(
      (selectedPosition) => selectedPosition._id === position._id
    );

    if (alreadySelected) {
      setSelectedPositions((prevPositions) =>
        prevPositions.filter(
          (selectedPosition) => selectedPosition._id !== position._id
        )
      );
    } else {
      setSelectedPoint(position);
      setSelectedPointIndex(index);
      setTooltipPosition({
        left: rect.left + rect.width / 2,
        top: rect.top - 10,
      });
      setShowPlotdet(true);
      if (position._id) {
        // setSelectPlot(position._id);
      }
      // setModalOpen(true);
      // setSavedPositions((prevSaved) => new Set(prevSaved).add(position._id));
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowPlotdet(false);
    };

    window.addEventListener("scroll", handleScroll);
    // if (isSelecting) {
    //   // eslint-disable-next-line array-callback-return
    //   layoutList.map((position: any) => {
    //     getSelectedItems(position);
    //   });
    // }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  console.log("===>showPlotdet", showPlotdet);

  return (
    <div>
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        width="100%"
      >
        <div>
          <IconButton
            sx={{
              backgroundColor: "#FF9037",
              color: "white",
              marginRight: 2,
              marginBottom: 1,
            }}
            onClick={zoomIn}
          >
            <ZoomInIcon />
          </IconButton>
          <IconButton
            disabled={zoomLevel === 1}
            sx={{
              backgroundColor: zoomLevel === 1 ? "#FF9037" : "#ff5a1f",
              color: "white",
              marginRight: 2,
              marginBottom: 1,
            }}
            onClick={zoomOut}
          >
            <ZoomOutIcon />
          </IconButton>
          <IconButton
            sx={{ backgroundColor: "#FF9037", color: "white" }}
            onClick={resetPosition}
          >
            <RestoreIcon />
          </IconButton>
        </div>
        <div>
          {mode === "IFRAME" ? null : (
            <Button
              variant="contained"
              className="btn"
              endIcon={<CropFreeIcon />}
              onClick={handleDialogToogle}
            >
              Iframe View
            </Button>
          )}
        </div>
      </Stack>

      {project?.layout_image && (
        <>
          {/* Container for zoomable content */}
          <div className="zoom-container" ref={containerRef}>
            {/* Inner content: Image and SVG */}
            <div
              className="zoom-content"
              style={{
                transform: `scale(${zoomLevel})`,
                transformOrigin: "center",
              }}
            >
              <img
                src={project.layout_image}
                alt="Plot Layout"
                style={{ width: "100%", height: "100%", marginTop: "5px" }}
                onLoad={(event) => {
                  const img = event.target as HTMLImageElement;
                  setOriginalImageWidth(img.naturalWidth);
                  setOriginalImageHeight(img.naturalHeight);
                }}
              />
              <svg
                className="svg-overlay"
                viewBox={`0 0 ${originalImageWidth} ${originalImageHeight}`}
              >
                {layoutPosition?.map((layout, index) => (
                  <path
                    key={index}
                    id={layout._id}
                    d={layout.path}
                    className="shape"
                    onClick={(e) => {
                      handleSelect(e, layout, index);
                    }}
                  />
                ))}
              </svg>
            </div>
            {showPlotdet &&
              selectedPoint &&
              selectedPointIndex !== null && ( // this is the tooltip for the clicked one
                <div
                  style={{
                    position: "fixed",
                    left: tooltipPosition.left,
                    top: tooltipPosition.top,
                    transform: "translate(-50%, -100%)",
                    backgroundColor: "white",
                    color: "black",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    border: "1px solid gray",
                  }}
                  onMouseEnter={() => {
                    setShowPlotdet(true);
                  }}
                >
                  <ToolTip
                    selectedPoint={selectedPoint}
                    mode={mode}
                    key={0}
                    handleCloseToolTip={handleCloseToolTip}
                  />

                  <div
                    style={{
                      width: "0",
                      height: "0",
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",
                      borderTop: "10px solid white",
                      position: "absolute",
                      left: "50%",
                      bottom: "-10px",
                      transform: "translateX(-50%)",
                    }}
                  />
                </div>
              )}
          </div>
        </>
      )}
      <IFrameModal
        project_title={project?.project_name || "Builder Project"}
        dialogOpen={iframeDialogOpen}
        handleDialogToogle={handleDialogToogle}
        src={`${IFRAME_BASE}/iframe-view/${builderId}/${plotId}`}
      />
    </div>
  );
};

export default ProjctLayout;
