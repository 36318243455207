import { AppBar, Chip, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../../configs/colorConfigs";
import sizeConfigs from "../../../configs/sizeConfigs";
import { useContext } from "react";
import UIContext from "../../../contexts/uiContext/UIContext";
import Button from "@mui/material/Button";
import React from "react";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import BuilderContext from "../../../contexts/builderContext/BuilderContext";
import { ROLES } from "../../../constants/roles/Roles";
import Dropdown from "../../shared/dropDown/Dropdown";
import { useLocation } from "react-router-dom";

interface TopbarProps {
  sidebarOpen: boolean;
  toggleSidebar: () => void; // Add a toggleSidebar prop of type function
}

const Topbar: React.FC<TopbarProps> = ({ sidebarOpen, toggleSidebar }) => {
  const { dashboardHeader } = useContext(UIContext);
  const { builderDetails } = useContext(BuilderContext);
  const allowedRolesForDropdown = [
    ROLES.super_admin,
    ROLES.landlord,
    ROLES.marketer,
    ROLES.reseller,
  ];

  const location = useLocation();
  const isOnAdminPage = location.pathname.startsWith("/admin");
  const isOnOwnerPage = location.pathname.startsWith("/landlord");

  return (
    <AppBar
      position="fixed"
      elevation={3}
      sx={{
        width: sidebarOpen
          ? `calc(100% - ${sizeConfigs.sidebar.width})`
          : "100%",
        ml: sidebarOpen ? sizeConfigs.sidebar.width : 0,
        backgroundColor: "#F8F8FF",
        color: colorConfigs.topbar.color,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" style={{ color: "#333333" }}>
          {dashboardHeader}
        </Typography>
        <div
          className="button-group"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Chip
            label={
              isOnAdminPage
                ? builderDetails?.builder_name || "Admin Portal"
                : isOnOwnerPage
                ? "Landlord Portal"
                : "Super Admin Portal"
            }
            style={{
              backgroundColor: "#FFA500", // Orange shade
              color: "#FFFFFF", // White text color
              fontFamily: "Arial, sans-serif", // Use a clean and common font family
              fontWeight: "bold", // Make the font bold
              fontSize: "16px", // Set a good font size
            }}
          />
          <NotificationsOutlinedIcon sx={{ fontSize: "30px", color: "gray" }} />

          {allowedRolesForDropdown ? (
            <Dropdown />
          ) : (
            <div
              className="button-group"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button className="green-contained-button" variant="outlined">
                {builderDetails?.builder_name}
              </Button>
              <Dropdown />
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
