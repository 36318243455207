import { useContext, useEffect, useState } from "react";
import { Switch, SelectChangeEvent } from "@mui/material";
import { IProject } from "../../../../../@types/interface/Projects";
import AuthContext from "../../../../../contexts/authContext/authContext";
import SwitchCellRenderer from "../../../../shared/cellRenderer/SwitchCellRenderer";
import { api } from "../../../../../utils/api";
import { ROLES } from "../../../../../constants/roles/Roles";
import { PROJECT_STATUS } from "../../../../../constants/projectStatus/ProjectStatus";

// import SwitchModel from "../modal/SwitchModel";

const ApproveCellRenderer = ({ data }: { data: IProject }) => {
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const { user } = useContext(AuthContext);

  const handleStatusChange = async (formData: any) => {
    if(user?.role === ROLES.super_admin){
      const _payload = {
        projectId: data._id,
        payload:{
          status: isApproved ? PROJECT_STATUS.rejected : PROJECT_STATUS.confrmed
        }
      };
      const response = await api.project.updateProject(_payload);
      if (response) {
        setIsApproved(true);
      }
    }
  };

  useEffect(() => {
    setIsApproved(data.status === PROJECT_STATUS.confrmed);
  }, [data]);

  return (
    <Switch color="success" checked={isApproved} onChange={handleStatusChange} />
  );
};

export default ApproveCellRenderer;
