import { Button, CardContent, Grid, Typography } from "@mui/material";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import { ILayout } from "../../../../@types/interface/layout.interface";
import { useState } from "react";

const ToolTip = ({
  selectedPoint,
  handleCloseToolTip,
  mode,
}: {
  selectedPoint: ILayout;
  handleCloseToolTip: () => void;
  mode: "ALL" | "IFRAME" | "ENQUIRY";
}) => {
  const [enquiryOpen, setEnquiryOpen] = useState<boolean>(false);

  const handleEnquiryToogle = () => {
    setEnquiryOpen(!enquiryOpen);
  };

  return (
    <div>
      <CardContent style={{ maxWidth: "300px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              <strong>Land Type:</strong> {selectedPoint.land_type}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              <strong>Land Type:</strong> {selectedPoint.land_type}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              <strong>Plot Number:</strong> {selectedPoint.plot_number}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              <strong>Plot Area:</strong> {selectedPoint.plot_size}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              <strong>Facing:</strong> {selectedPoint.facing}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              <strong>Dimension:</strong> {selectedPoint.dimension}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            {mode === "IFRAME" ? (
              <Button
                variant="contained"
                className="btn"
                endIcon={<AddIcCallIcon />}
                onClick={handleEnquiryToogle}
              >
                Enquiry
              </Button>
            ) : (
              <Button
                variant="outlined"
                className="green-outlined-button"
                onClick={handleCloseToolTip}
              >
                Close
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </div>
  );
};

export default ToolTip;
