import { useContext, useEffect, useState } from "react";
import { CustomerColDefs } from "../../../constants/customer/customerColDefs";
import { api } from "../../../utils/api";
import { ICustomer } from "../../../@types/interface/Customer.interface";
import DataGrid from "../../shared/dataGrid/DataGrid";
import UIContext from "../../../contexts/uiContext/UIContext";
import BasicPagination from "../../shared/basicPagination/BasicPagination";
import Download from "../../shared/downloadbtn/Download";
import { downloadExcel } from "../../../utils/commonFunction/downloadExcel";
import { IPagination } from "../../../@types/interface/pagination";
// import DataGrid from "../../shared/dataGrid/DataGrid";

const AllCustomersPage = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [customerData, setCustomerData] = useState<ICustomer[]>([]);
  const [allCustomerPagination, setCustomerPagination] = useState<IPagination>({
    currentPage: 1,
    pageCount: 1,
  });
  const fetchCustomers = async (filterQuery: any, page: number) => {
    try {
      const response = await api.customer.getCustomer(filterQuery, page);
      // console.log("Api-Check---REsponse--->", response[0].full_name);
      if (response) {
        const formattedData = response.map((customer: ICustomer) => ({
          ...customer,
          phone: customer.phone ?? "N/A",
          gender: customer.gender ?? "N/A",
          state: customer.state ?? "N/A",
          address: customer.address ?? "N/A",
          referal_code: customer.referal_code ?? "N/A",
          refered_by_code: customer.refered_by_code ?? "N/A",
          createdAt: customer.createdAt
            ? new Date(customer.createdAt).toLocaleDateString("en-GB")
            : "N/A",
          updatedAt: customer.updatedAt
            ? new Date(customer.updatedAt).toLocaleDateString("en-GB")
            : "N/A",
        }));
        setCustomerData(formattedData);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const onDownloadClick = downloadExcel(customerData, "customer");
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    fetchCustomers({}, currentPage);
  }, [currentPage]);

  useEffect(() => {
    setDashboardHeader("All Customer");
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
          marginBottom: "20px",
        }}
      >
        <Download onClick={onDownloadClick} />
      </div>
      <DataGrid colDefs={CustomerColDefs} rowData={customerData} key={0} />
      <BasicPagination
        currentPage={allCustomerPagination.currentPage}
        handlePageChange={handlePageChange}
        pageCount={allCustomerPagination.pageCount}
      />
      {/* <BasicPagination /> */}
    </div>
  );
};

export default AllCustomersPage;
