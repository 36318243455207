import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  boxShadow: 14,
  borderRadius: 5,
  p: 4,
};

interface SwitchModelProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  title: string;
  children: ReactNode;
}

const SwitchModel = ({
  open,
  onConfirm,
  onClose,
  title,
  children,
}: SwitchModelProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
        {children}
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="success"
            onClick={onConfirm}
            sx={{ mr: 1 }}
          >
            Send
          </Button>
          <Button variant="outlined" color="error" onClick={onClose}>
            No
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SwitchModel;
