import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import Chip from "@mui/material/Chip";
import { VisuallyHiddenInput } from "./VisualInput";
import ProjectFormTextField from "../../../../../shared/formTextField/projectFormTextField/ProjectFormTextField";
import ProjectFormSelectField from "../../../../../shared/formSelectField/projectFormSelectField/ProjectFormSelectField";
// import TextareaAutosize from "@mui/material/TextareaAutosize";

import {
  ConstructionStatusOptions,
  ProjectAvailabilityOptions,
  ProjectCategoryOptions,
  ProjectOptions,
} from "../../../../../../constants/addPlotsFormFields/ProjectOptions";
import { FacingOptions } from "../../../../../../constants/addPlotsFormFields/PlotFacingOptions";
import { STATE_AUTHORITIES } from "../../../../../../constants/stateAuthority/StateAuthority";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import "./acoordianStyles.css";
import { RichTextEditor } from "../../../../../shared/richEditor/RichTextEditor";
import ProjectFormWithUnit from "../../../../../shared/formTextField/projectFormWithUnit/ProjectFormWIthUnit";
import ProjectFormMultipleSelectField from "../../../../../shared/formTextField/projectFormMultipleSelectField/projectFormMultipleSelectField";

const ProjectDetailsAccordion: React.FC<any> = ({
  projectLogo,
  setProjectLogo,
  projectDetails,
  handleChange,
  handleDetailsChange,
  handleProjectTypeChange,
  uploadedFile,
  handleAuthorityChange,
  handleInputChange,
  selectedState,
  handleStateChange,
  setUploadedFile,
  selectedAuthorities,
}) => {
  const [priceUnit, setPriceUnit] = React.useState("acre");
  const [convertedValue, setConvertedValue] = useState<any>("");
  // const [projectCategory,setProjectCategory] = useState<any[]>([]);

  const handleUnitChange = (event: SelectChangeEvent<string>) => {
    const customEvent = {
      target: {
        name: "price_unit",
        value: event.target.value,
      },
    };
    handleChange(customEvent as React.ChangeEvent<HTMLInputElement>);
    setPriceUnit(event.target.value);
  };

  return (
    <Accordion defaultExpanded style={{ display: "block" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h3>Project Details</h3>
      </AccordionSummary>
      <AccordionDetails>
        <ProjectFormTextField
          className={"big-input-box"}
          required={true}
          width={"55%"}
          projectDetails={projectDetails}
          name={"project_name"}
          label={"Project Name"}
          type={"text"}
          handleChange={handleChange}
        />
        <ProjectFormMultipleSelectField
          className={"big-input-box"}
          required={true}
          width={"55%"}
          projectDetails={projectDetails}
          name={"prroject_type"}
          label={"Project Type"}
          type={"text"}
          handleProjectTypeChange={handleProjectTypeChange}
        />
        <ProjectFormSelectField
          className={"big-input-box"}
          width={"55%"}
          projectDetails={projectDetails}
          required={true}
          name={"project_availability"}
          label={"Project Availability"}
          inputLabel="Select Project Type"
          handleChange={handleChange}
          options={ProjectAvailabilityOptions}
          isAddMore={false}
        />
        <ProjectFormTextField
          className={"big-input-box"}
          width={"55%"}
          projectDetails={projectDetails}
          name={"rera_number"}
          label={"RERA Number"}
          type={"text"}
          handleChange={handleChange}
        />

        <ProjectFormTextField
          className={"big-input-box"}
          width={"55%"}
          projectDetails={projectDetails}
          name={"tlp_number"}
          label={"TLP Number"}
          type={"text"}
          handleChange={handleChange}
        />

        <ProjectFormSelectField
          className={"big-input-box"}
          width={"55%"}
          projectDetails={projectDetails}
          required={true}
          name={"project_category"}
          label={"Project Category"}
          inputLabel="Select Project Category"
          handleChange={handleChange}
          options={ProjectCategoryOptions}
        />
        <ProjectFormTextField
          className={"big-input-box"}
          width={"55%"}
          projectDetails={projectDetails}
          name={"total_plots"}
          label={"Total plots:"}
          type={"number"}
          handleChange={handleChange}
        />
        <ProjectFormTextField
          className={"big-input-box"}
          width={"55%"}
          required={true}
          projectDetails={projectDetails}
          name={"price_per_sq"}
          label={"Price/Unit:"}
          type={"number"}
          unitOptions={["ACRE", "SQFT"]}
          unit={priceUnit}
          handleUnitChange={handleUnitChange}
          handleChange={handleInputChange}
        />
        <ProjectFormTextField
          className={"big-input-box"}
          width={"55%"}
          required={true}
          projectDetails={projectDetails}
          name={"total_sq_feet"}
          label={"Total Area (sq.acres):"}
          type={"number"}
          handleChange={handleChange}
        />

        <ProjectFormSelectField
          required={false}
          className={"big-input-box"}
          width={"55%"}
          projectDetails={projectDetails}
          name={"city_facing"}
          label={"City Facing:"}
          inputLabel="Facing"
          handleChange={handleChange}
          options={FacingOptions}
        />
        <ProjectFormTextField
          className={"big-input-box"}
          width={"55%"}
          required={true}
          projectDetails={projectDetails}
          name={"highway_facing"}
          label={"Highway Facing"}
          type={"text"}
          handleChange={handleChange}
        />
        <ProjectFormTextField
          className={"big-input-box"}
          width={"55%"}
          required={false}
          projectDetails={projectDetails}
          name={"road_size"}
          label={"Road Size (in meter)"}
          type={"number"}
          handleChange={handleChange}
        />

        <div className="state-authorites-inputs">
          <div className="select-input-box">
            <label style={{ fontSize: "16px" }}>
              State{" "}
              <span style={{ color: "red", fontSize: 20, fontWeight: "bold" }}>
                *
              </span>
            </label>
            <Select
              value={selectedState}
              onChange={handleStateChange}
              displayEmpty
              style={{ width: "55%", margin: "10px 0" }}
            >
              <MenuItem value="" disabled>
                Select State
              </MenuItem>
              {STATE_AUTHORITIES.map((item) => (
                <MenuItem key={item.state} value={item.state}>
                  {item.state}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>

        <ProjectFormSelectField
          className={"big-input-box"}
          width={"55%"}
          projectDetails={projectDetails}
          name={"construction_status"}
          label={"Construction Status:"}
          inputLabel="Select Status"
          handleChange={handleChange}
          options={ConstructionStatusOptions}
        />

        <ProjectFormTextField
          className={"big-input-box"}
          width={"55%"}
          projectDetails={projectDetails}
          name={"construction_date"}
          label={"Construction Date:"}
          type={"text"}
          handleChange={handleChange}
        />

        <ProjectFormTextField
          className={"big-input-box"}
          width={"55%"}
          required={true}
          projectDetails={projectDetails}
          name={"launching_date"}
          label={"Launching Date:"}
          type={"text"}
          handleChange={handleChange}
        />

        <div className="state-authorites-inputs">
          <div className="select-input-box">
            <label style={{ fontSize: "16px" }}>
              Approved By{" "}
              <span style={{ color: "red", fontSize: 20, fontWeight: "bold" }}>
                *
              </span>
            </label>
            <Select
              multiple
              value={projectDetails.approved_by}
              onChange={handleAuthorityChange}
              displayEmpty
              style={{ width: "55%", margin: "10px 0" }}
              disabled={!selectedState}
              renderValue={(selected) => {
                if ((selected as string[]).length === 0) {
                  return "Select Authority";
                }
                return (selected as string[]).join(", ");
              }}
            >
              <MenuItem value="" disabled>
                Select Authority
              </MenuItem>
              {selectedAuthorities.map((approved_by: string) => (
                <MenuItem key={approved_by} value={approved_by}>
                  <Checkbox
                    checked={
                      projectDetails.approved_by.indexOf(approved_by) > -1
                    }
                  />
                  <ListItemText primary={approved_by} />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: "12px",
          }}
        >
          <label className="labels" style={{ paddingLeft: 25 }}>
            Description{" "}
            <span style={{ color: "red", fontSize: 20, fontWeight: "bold" }}>
              *
            </span>
          </label>
          <RichTextEditor
            value={projectDetails.description}
            onChange={(value) => handleDetailsChange(value)}
          />
        </div>
      </AccordionDetails>
      <div
        className="big-input-box"
        style={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          gap: 40,
          marginLeft: 15,
        }}
      >
        <ProjectFormWithUnit
          lebel={"Min Plot Size:"}
          convertedValue={convertedValue}
          setConvertedValue={setConvertedValue}
        />
        <ProjectFormWithUnit
          lebel={"Max Plot Size:"}
          convertedValue={convertedValue}
          setConvertedValue={setConvertedValue}
        />
        <br />

        {/* <ProjectFormTextField
           className={"label-input-flex"}
           width={"100%"}
           projectDetails={projectDetails}
           name={"max_plot_size"}
           label={"Max Plot Size:"}
           type={"number"}
           unitOptions={["acre", "sq feet"]}
           selectedUnit={priceUnit}
           handleUnitChange={handleUnitChange}
           handleChange={handleChange}
         /> */}
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <label className="labels" style={{ paddingLeft: 30 }}>
          Upload Project Logo:
        </label>
        <Button
          style={{
            marginTop: 20,
            marginBottom: 20,
            marginRight: 60,
          }}
          endIcon={<UploadOutlinedIcon />}
          className="btn"
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
        >
          Upload Project Logo
          <VisuallyHiddenInput
            type="file"
            accept="image/*"
            onChange={(event: any) => {
              const file = event.target.files?.[0];
              if (file) {
                setProjectLogo(file);
              }
            }}
            required
          />
        </Button>
        {projectLogo && (
          <Chip
            label={projectLogo.name}
            onDelete={() => setProjectLogo(null)}
            variant="outlined"
            sx={{ marginTop: 1, marginRight: 20 }}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <label className="labels" style={{ paddingLeft: 30 }}>
          Upload Plot Image:
        </label>
        <Button
          style={{
            marginTop: 20,
            marginBottom: 20,
            marginRight: 60,
          }}
          endIcon={<UploadOutlinedIcon />}
          className="btn"
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
        >
          Upload Plot Image
          <VisuallyHiddenInput
            type="file"
            accept="image/*"
            onChange={(event: any) => {
              const file = event.target.files?.[0];
              if (file) {
                setUploadedFile(file);
              }
            }}
            required
          />
        </Button>
        {uploadedFile && (
          <Chip
            label={uploadedFile.name}
            onDelete={() => setUploadedFile(null)}
            variant="outlined"
            sx={{ marginTop: 1, marginRight: 20 }}
          />
        )}
      </div> */}
    </Accordion>
  );
};

export default ProjectDetailsAccordion;
