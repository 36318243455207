import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  Backdrop,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  SelectChangeEvent,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import SendIcon from "@mui/icons-material/Send";

import Button from "@mui/material/Button";

import { api } from "../../../../../utils/api";
import BuilderContext from "../../../../../contexts/builderContext/BuilderContext";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import { useNavigate } from "react-router-dom";
import {
  IProject,
  IProjectAminity,
} from "../../../../../@types/interface/Projects";
import {
  defaultNearbyForm,
  projectOverviewForm,
  defaultProjectForm,
  projectFacilityForm,
  locationHighlightsForm,
  projectAttractionForm,
} from "../../../../../defaultValue/projectForm/ProjectForm";
import AuthContext from "../../../../../contexts/authContext/authContext";
import "./addplot.css";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import ProjectDetailsAccordion from "./accordians/ProjectDetailsAccrodian";
import AddressDetailsAccordion from "./accordians/AddressAccordian";
import BankDetailsAccordion from "./accordians/BankDetailsAccordian";
import TransportsAccordian from "./accordians/TransportsAccordian";
import { ITransport } from "../../../../../@types/interface/transport.interface";
import OverviewAccordian from "./accordians/MultipleArrayAccordian";
import { IOverview } from "../../../../../@types/interface/overview.interface";
import { STATE_AUTHORITIES } from "../../../../../constants/stateAuthority/StateAuthority";
import AminitiesAccordian from "./accordians/AminitiesAccordian";
import IconDropdown from "../../../../shared/iconDropdown/IconDropdown";
import LocationPlanImageAccordian from "./accordians/LocationPlotAccrodian";
import OverviewAndLocationAccordian from "./accordians/MultipleArrayAccordian";
import { ILocationsHighlights } from "../../../../../@types/interface/locationHighlights.interface";
import MultipleArrayAccordian from "./accordians/MultipleArrayAccordian";
import { IHighlight } from "../../../../../@types/interface/highlight.interface";
import { VisuallyHiddenInput } from "./accordians/VisualInput";
import CloseIcon from "@mui/icons-material/Close";
import PlotAccessibility from "./plotAccessibility/PlotAccessibility";

const AddPlotsForm = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { setDashboardHeader } = useContext(UIContext);
  const { builderDetails } = useContext(BuilderContext);
  const [projectDetails, setProjectDetails] =
    useState<IProject>(defaultProjectForm);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [projectLogo, setProjectLogo] = useState<File | null>(null);
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedAuthorities, setSelectedAuthorities] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [transports, setTransports] = useState<ITransport[]>([
    defaultNearbyForm,
  ]);
  const steps = ["Step 1", "Step 2", "Step 3"];
  const [locationHighlightsImage, setLocationHighlightsImage] =
    useState<File | null>(null);

  const [locationPlanImage, setLocationPlanImage] = useState<File | null>(null);

  const [overview, setOverview] = useState<IOverview[]>([projectOverviewForm]);

  const [projectAttraction, setProjectAttraction] = useState<IHighlight[]>([
    projectAttractionForm,
  ]);

  const [locationHighlights, setLocationHighlights] = useState<
    ILocationsHighlights[]
  >([locationHighlightsForm]);

  const [facility, setFacility] = useState<IProjectAminity[]>([
    projectFacilityForm,
  ]);

  const [snackbarType, setSnackbarType] = useState<"success" | "error">(
    "success"
  );
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const queryParams = new URLSearchParams(window.location.search);
  const projectId = queryParams.get("pid");

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const changePage = () => {
    setPage((prevPage) => prevPage + 1);
  };
  const PrevPage = () => {
    setPage((prevPage) => prevPage - 1);
  };
  const getStepContent = (step: any) => {
    switch (step) {
      case 0:
        return <Typography>Content for Step 1</Typography>;
      case 1:
        return <Typography>Content for Step 2</Typography>;
      default:
        return <Typography>Unknown Step</Typography>;
    }
  };
  const stripHtmlTags = (html: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const handleStateChange = (event: SelectChangeEvent<string>) => {
    const state = event.target.value as string;
    setSelectedState(state);
    console.log("Selected State:", state);
    const authorities =
      STATE_AUTHORITIES.find((item) => item.state === state)?.authorities || [];
    setSelectedAuthorities(authorities);
    console.log("Available Authorities for the selected state:", authorities);

    // Reset the "approved_by" field when the state changes
    setProjectDetails({
      ...projectDetails,
      state: state, // Update the state field
      approved_by: [],
    });
  };

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      [name]: parseFloat(value),
    }));
  };

  const handleAuthorityChange = (event: SelectChangeEvent<string[]>) => {
    const authorities = event.target.value as string[];
    console.log("Selected Authorities:", authorities);
    setProjectDetails({
      ...projectDetails,
      approved_by: authorities,
    });
  };

  const handleProjectTypeChange = (event: { target: { value: any } }) => {
    const { value } = event.target;
    setProjectDetails({ ...projectDetails, project_type: value });
  };

  const handleDetailsChange = (value: string) => {
    if (projectId) {
      if (projectDetails !== defaultProjectForm) {
        setProjectDetails(
          Object.assign({}, projectDetails, { description: value })
        );
      }
    } else {
      setProjectDetails(
        Object.assign({}, projectDetails, { description: value })
      );
    }
  };

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>,
    child?: ReactNode
  ) => {
    const { name, value } = event.target;
    setProjectDetails(Object.assign({}, projectDetails, { [name]: value }));
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      const builder_object_id = builderDetails?._id || "";
      delete projectDetails._id;
      const updatedProjectDetails = {
        ...projectDetails,
        builder_object_id,
        root_project_object_id: projectId ? projectId : null,
        description: stripHtmlTags(projectDetails.description),
      };
      formData.append("projectDetails", JSON.stringify(updatedProjectDetails));
      formData.append("nearby", JSON.stringify(transports));
      formData.append("overview", JSON.stringify(overview));
      formData.append("highlights", JSON.stringify(projectAttraction));
      formData.append("locationHighlights", JSON.stringify(locationHighlights));
      formData.append("facilities", JSON.stringify(facility));
      if (uploadedFile) {
        formData.append("layout", uploadedFile);
      }
      if (projectLogo) {
        formData.append("project_logo", projectLogo);
      }
      if (locationPlanImage) {
        formData.append("location_plan_image", locationPlanImage);
      }
      if (locationHighlightsImage) {
        formData.append("location_highlight_image", locationHighlightsImage);
      }

      const response = await api.project.addPlot(formData);
      if (response) {
        console.log("ADDED__RESPONSE---->", response);
        alert("Data submitted successfully!");
        setSnackbarOpen(true);
        setSnackbarType("success");
        setSnackbarMessage("Data submitted successfully!");
        // Reset form fields
        setProjectDetails(defaultProjectForm);
        setUploadedFile(null);
        setProjectLogo(null);
        if (projectId) {
          navigate(`/project-details?pid=${response?._id}`);
        } else {
          navigate(`/upload-project-media?pid=${response?._id}`);
        }
      } else {
        throw new Error(`API request failed with status ${response.status}`);
      }
    } catch (error) {
      console.error("Error while adding", error);
      // alert("Error while submitting data");
      setSnackbarOpen(true);
      setSnackbarType("error");
      setSnackbarMessage("Error while submitting data");
    } finally {
      setLoading(false);
    }
  };

  const getProjectDetails = useCallback(async () => {
    if (projectId) {
      const payload = {
        project_object_id: projectId,
      };
      const projectDetails = await api.project.getProjectDetails(payload);
      if (projectDetails) {
        setProjectDetails(projectDetails.project_details);
      }
    }
  }, [projectId]);

  useEffect(() => {
    getProjectDetails();
    console.log("===>called");
  }, [getProjectDetails]);

  useEffect(() => {
    setDashboardHeader("Add Plots");
  }, [setDashboardHeader]);

  console.log("project details--->", projectDetails);

  return (
    <form onSubmit={handleSubmit}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <br />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={snackbarType}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        <div
          style={{
            width: "70%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 20,
          }}
        >
          <Stepper activeStep={page}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {page === 0 ? (
          <>
            <div>
              <ProjectDetailsAccordion
                projectDetails={projectDetails}
                handleChange={handleChange}
                handleProjectTypeChange={handleProjectTypeChange}
                projectLogo={projectLogo}
                setProjectLogo={setProjectLogo}
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
                handleDetailsChange={handleDetailsChange}
                handleAuthorityChange={handleAuthorityChange}
                handleInputChange={handleInputChange}
                selectedState={selectedState}
                handleStateChange={handleStateChange}
                selectedAuthorities={selectedAuthorities}
              />
            </div>
            <br />
            {/* ADDRESS DETAILS ACCORDION STARTS HERE */}
            <AddressDetailsAccordion
              projectDetails={projectDetails}
              setProjectDetails={setProjectDetails}
              handleChange={handleChange}
            />
            <br />
            <br />
            {projectId ? null : (
              <>
                <AminitiesAccordian
                  facility={facility}
                  setFacility={setFacility}
                />
                <MultipleArrayAccordian
                  overview={projectAttraction}
                  setOverview={setProjectAttraction}
                  title="Project Attractions"
                  mode="HIGHLIGHT"
                />
                <MultipleArrayAccordian
                  overview={overview}
                  setOverview={setOverview}
                  title="Project Highlights"
                  mode="OVERVIEW"
                />
                <MultipleArrayAccordian
                  overview={locationHighlights}
                  setOverview={setLocationHighlights}
                  title="Location Advantages"
                  mode="LOCATION"
                />
                <TransportsAccordian
                  transports={transports}
                  setTransports={setTransports}
                />
                <br />
                <LocationPlanImageAccordian
                  locationPlanImage={locationPlanImage}
                  setLocationPlanImage={setLocationPlanImage}
                  locationHighlightsImage={locationHighlightsImage}
                  setLocationHighlightsImage={setLocationHighlightsImage}
                />
                <br />

                <BankDetailsAccordion
                  projectDetails={projectDetails}
                  setProjectDetails={setProjectDetails}
                  handleChange={handleChange}
                />
              </>
            )}

            <Button
              onClick={changePage}
              className="btn"
              variant="contained"
              endIcon={<SendIcon />}
              style={{
                width: "100px",
                padding: "5px",
                backgroundColor: "#49BB43",
              }}
              sx={{
                marginTop: 2,
                marginLeft: 5,
                marginBottom: 5,
              }}
            >
              Next
            </Button>
          </>
        ) : (
          <>
            <Paper elevation={4}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label className="labels" style={{ paddingLeft: 30 }}>
                  Upload Project Logo:
                </label>
                <Button
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    marginRight: 60,
                  }}
                  endIcon={<UploadOutlinedIcon />}
                  className="btn"
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                >
                  Upload Project Logo
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(event: any) => {
                      const file = event.target.files?.[0];
                      if (file) {
                        setProjectLogo(file);
                      }
                    }}
                    required
                  />
                </Button>
                {projectLogo && (
                  // <Chip
                  //   label={projectLogo.name}
                  //   onDelete={() => setProjectLogo(null)}
                  //   variant="outlined"
                  //   sx={{ marginTop: 1, marginRight: 20 }}
                  // />
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      marginTop: 20,
                      marginRight: 50,
                    }}
                  >
                    <img
                      src={URL.createObjectURL(projectLogo)}
                      alt="Location Plan"
                      style={{
                        width: 200, // adjust the size as needed
                        height: 150,
                        borderRadius: 8,
                        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                      }}
                    />
                    <IconButton
                      onClick={() => setProjectLogo(null)}
                      style={{
                        position: "absolute",
                        top: -10,
                        right: -10,
                        backgroundColor: "white",
                        borderRadius: "50%",
                        boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
                      }}
                      size="small"
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}
              </div>
              {projectDetails.project_availability === "OFFLINE" ? null : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label className="labels" style={{ paddingLeft: 30 }}>
                    Upload Plot Image:
                  </label>
                  <Button
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginRight: 60,
                    }}
                    endIcon={<UploadOutlinedIcon />}
                    className="btn"
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                  >
                    Upload Plot Image
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      onChange={(event: any) => {
                        const file = event.target.files?.[0];
                        if (file) {
                          setUploadedFile(file);
                        }
                      }}
                      required
                    />
                  </Button>
                  {uploadedFile && (
                    // <Chip
                    //   label={uploadedFile.name}
                    //   onDelete={() => setUploadedFile(null)}
                    //   variant="outlined"
                    //   sx={{ marginTop: 1, marginRight: 20 }}
                    // />
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        marginTop: 20,
                        marginRight: 50,
                      }}
                    >
                      <img
                        src={URL.createObjectURL(uploadedFile)}
                        alt="Location Plan"
                        style={{
                          width: 200, // adjust the size as needed
                          height: 150,
                          borderRadius: 8,
                          boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                        }}
                      />
                      <IconButton
                        onClick={() => setUploadedFile(null)}
                        style={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                          backgroundColor: "white",
                          borderRadius: "50%",
                          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
                        }}
                        size="small"
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
              )}
            </Paper>
            <PlotAccessibility />
            <div className="" style={{ display: "flex", gap: 5 }}>
              <Button
                className="btn"
                variant="contained"
                onClick={PrevPage}
                style={{
                  width: "100px",
                  padding: "5px",
                  backgroundColor: "black",
                }}
                sx={{
                  marginTop: 2,
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                Back
              </Button>
              <Button
                className="btn"
                variant="contained"
                endIcon={<SendIcon />}
                style={{
                  width: "100px",
                  padding: "5px",
                  backgroundColor: "#49BB43",
                }}
                type="submit"
                sx={{
                  marginTop: 2,
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                Next
              </Button>
            </div>
          </>
        )}
        {/* PROJECT DETAILS ACCORDION STARTS HERE */}
      </div>
    </form>
  );
};

export default AddPlotsForm;
