export const ProjectOptions = [
  { label: "Residential", value: "RESIDENTIAL" },
  { label: "Commercial", value: "COMMERCIAL" },
];

export const ProjectCategoryOptions = [
  { label: "Villa", value: "VILLA" },
  { label: "Farm Land", value: "FARM LANDS" },
  { label: "Office Space", value: "OFFICE SPACE" },
];

export const ProjectAvailabilityOptions = [
  { label: "Online", value: "ONLINE" },
  { label: "Offline", value: "OFFLINE" },
];

export const ConstructionStatusOptions = [
  { label: "Under Construction", value: "UNDER_CONSTRUCTION" },
  { label: "Completed", value: "COMPLETED" },
];
