import React, { useState, useRef } from "react";
import image from "../../../../assets/images/My password-amico.svg";
import { Button } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import "./verifylogin.css";
import { useLocation, useNavigate } from "react-router-dom";

const VerifyLogin = () => {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const location = useLocation();
  const navigate = useNavigate();
  const { otpCheck } = location.state;

  const inputsRef = useRef<Array<HTMLInputElement | null>>([]);

  const handleOtpChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    if (isNaN(Number(value)) || value.length > 1) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (index < 3 && value !== "") {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handleVerifyOTP = () => {
    const enteredOTP = otp.join("");

    if (enteredOTP === otpCheck.toString()) {
      navigate("/");
    } else {
      console.log("Invalid OTP");
    }
  };

  return (
    <div className="main-container">
      <div className="verify-login-container">
        <div className="verify-form">
          <div>
            <LockOutlinedIcon sx={{ fontSize: "35px", marginTop: "20px" }} />
          </div>
          <h2>OTP Verification</h2>
          <p className="para">Enter 4 digit code sent to your mobile number</p>

          <div className="otp-inputs">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleOtpChange(e, index)}
                ref={(input) => (inputsRef.current[index] = input)}
              />
            ))}
          </div>

          <Button
            className="btn"
            variant="contained"
            sx={{ width: "80%" }}
            endIcon={<VerifiedUserIcon />}
            onClick={handleVerifyOTP}
          >
            Verify OTP
          </Button>
          <p className="para2">
            Didn't receive any otp?{" "}
            <a href="#" className="resend-link">
              Resend OTP
            </a>
          </p>
        </div>
        <div style={{ width: "500px", height: "500px" }}>
          <img src={image} alt="password-amico" />
        </div>
      </div>
    </div>
  );
};

export default VerifyLogin;
