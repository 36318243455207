import { useContext, useState } from "react";
import { Switch } from "@mui/material";
import UIContext from "../../../contexts/uiContext/UIContext";
import SwitchModalForm from "../modalsDialog/SwitchModelForm";

const SwitchCellRenderer = ({
  checked,
  rowData,
  handleChange,
}: {
  checked: boolean;
  rowData: any;
  handleChange: (formData: any) => void;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { dashboardHeader } = useContext(UIContext);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleSwitchChange = () => {
    if (!checked) {
      handleOpenModal();
    }
  };

  const handleConfirmSwitchChange = (formData: any) => {
    handleChange(formData);
    handleCloseModal();
    console.log("Form Data:", formData);
  };

  return (
    <>
      <Switch color="success" checked={checked} onChange={handleSwitchChange} />

      {/* This Switch Form renders for the enquiry case only */}
      {dashboardHeader === "All Customer Enquiry" && (
        <SwitchModalForm
          open={modalOpen}
          onConfirm={handleConfirmSwitchChange}
          onClose={handleCloseModal}
          rowData={rowData}
        />
      )}
    </>
  );
};

export default SwitchCellRenderer;
