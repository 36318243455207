import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";

interface RichTextEditorProps {
  value: string;
  onChange: (value: string) => void;
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  onChange,
}) => {
  const handleChange = (value: string) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    onChange(sanitizedValue);
  };

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={handleChange}
      style={{ width: "54%" }}
    />
  );
};
