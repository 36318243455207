import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

import ProjectFormTextField from "../../../../../shared/formTextField/projectFormTextField/ProjectFormTextField";

import { IAddressAccodianInterface } from "../../../../../../@types/interface/addressAccordian.interface";

const AddressDetailsAccordion: React.FC<IAddressAccodianInterface> = ({
  projectDetails,
  handleChange,
}) => {
  return (
    <Accordion defaultExpanded style={{ display: "block", marginTop: "20px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <h3>Address Details</h3>
      </AccordionSummary>
      <AccordionDetails>
        {/* Input fields for address details can be added here */}

        <ProjectFormTextField
          className={"big-input-box"}
          width={"55%"}
          projectDetails={projectDetails}
          name={"plot_landmark"}
          label={"Plot Landmark:"}
          type={"text"}
          handleChange={handleChange}
          required={true}
        />
        <ProjectFormTextField
          className={"big-input-box"}
          width={"55%"}
          projectDetails={projectDetails}
          name={"location_google_link"}
          label={"Google Location Link:"}
          type={"url"}
          handleChange={handleChange}
          required={true}
        />
        <ProjectFormTextField
          className={"big-input-box"}
          width={"55%"}
          projectDetails={projectDetails}
          name={"formatted_address"}
          label={"Address:"}
          type={"text"}
          handleChange={handleChange}
          required={true}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default AddressDetailsAccordion;
