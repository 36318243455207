import { Paper, SelectChangeEvent } from "@mui/material";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { IProject } from "../../../../../@types/interface/Projects";
import { ReactNode } from "react";

const ProjectAccessibility = ({
  project,
  handleChangeProjectDetails,
  updateProject,
}: {
  project: IProject;
  handleChangeProjectDetails: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>,
    child?: ReactNode
  ) => void;
  updateProject: (payload: Partial<IProject>) => Promise<void>;
}) => {
  const createSyntheticEvent = (name: string, value: boolean) => ({
    target: { name, value },
  });

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    console.log("===>name", name, checked);
    handleChangeProjectDetails(
      createSyntheticEvent(
        name,
        checked
      ) as unknown as React.ChangeEvent<HTMLInputElement>
    );
    await updateProject({ [name]: checked });
  };
  return (
    <Paper elevation={3} style={{ padding: 10 }}>
      <h2 style={{ marginLeft: 20 }}>Accessibility</h2>
      <FormGroup style={{ paddingLeft: 20 }}>
        <FormControlLabel
          control={
            <Checkbox
              name="is_tooltip_visible"
              checked={project.is_tooltip_visible}
              onChange={handleCheckboxChange}
            />
          }
          label="Tooltip Visible"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="is_availability_visible"
              checked={project.is_availability_visible}
              onChange={handleCheckboxChange}
            />
          }
          label="Availability Visible"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="is_plot_detection_visible"
              checked={project.is_plot_detection_visible}
              onChange={handleCheckboxChange}
            />
          }
          label="Plot Detection Visible"
        />
      </FormGroup>
    </Paper>
  );
};

export default ProjectAccessibility;
