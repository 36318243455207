import { ColDef } from "ag-grid-community";
import DateCellRenderer from "../../../../shared/cellRenderer/DateCellRenderer";

export const EnquiryIframeColDefs : ColDef[] = [
    {field: "customer_name", headerName: "Name"},
    {field: "customer_phone", headerName: "Phone Number"},
    {field: "project_details.project_name", headerName: "Project Name"},
  
    
    {field: "project_details.formatted_address", headerName: "Address"},
    {field: "project_details.price_per_sq", headerName: "Price/sq"},
    {field: "project_details.construction_status", headerName: "Construction Status"},
    {field: "project_details.construction_date", headerName: "Construction Date"},
   
    {field: "project_details.createdAt", headerName: "Enquiry Date", cellRenderer: DateCellRenderer}

]