import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useCallback, useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import { enquiryColumnDef } from "../../../../constants/enquiry/EnquiryColDef";
import { IBooking } from "../../../../@types/interface/booking.interface";
import { api } from "../../../../utils/api";
import BuilderContext from "../../../../contexts/builderContext/BuilderContext";
import Download from "../../../shared/downloadbtn/Download";
import AuthContext from "../../../../contexts/authContext/authContext";
import { ROLES } from "../../../../constants/roles/Roles";
import { IPagination } from "../../../../@types/interface/pagination";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";

const NewEnquiry = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const { user } = useContext(AuthContext);
  const [bookingList, setBookingList] = useState<IBooking[]>([]);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const [allEnquiryPagination, setAllEnquiryPagination] = useState<IPagination>(
    {
      currentPage: 1,
      pageCount: 1,
    }
  );
  const queryParams = new URLSearchParams(window.location.search);
  const builderId = queryParams.get("cid");

  const getEnquiryData = useCallback(async () => {
    if (
      user?.role === ROLES.builder_admin ||
      ROLES.super_admin ||
      ROLES.project_manager ||
      ROLES.channel_partner ||
      ROLES.telecaller
    ) {
      const filter = {
        page: allEnquiryPagination.currentPage,
        sortField: "updatedAt",
        builder_object_id: builderId,
      };
      console.log("====>api", filter);
      const response = await api.booking.getBooking(filter);
      setBookingList(response.result);
      setAllEnquiryPagination(response.pagination);
    } else if (user?.role === "MARKETER" || "RESELLER") {
      const filter = {
        page: 1,
        sortField: "updatedAt",
        assigned_admin_object_id: user?._id,
      };
      console.log("====>api", filter);
      const response = await api.booking.getBooking(filter);
      setBookingList(response.result);
      setAllEnquiryPagination(response.pagination);
    } else {
      const filter = {
        page: 1,
        sortField: "updatedAt",
      };
      console.log("====>api", filter);
      const response = await api.booking.getBooking(filter);
      setBookingList(response.result);
    }
  }, [user]);

  const onRowClicked = (event: { data: any }) => {
    const rowData = event.data;
    setSelectedRowData(rowData);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setAllEnquiryPagination((prev) => ({
      ...prev,
      currentPage: value,
    }));
  };

  useEffect(() => {
    setDashboardHeader("All Customer Enquiry");
  }, [setDashboardHeader]);

  useEffect(() => {
    getEnquiryData();
  }, [getEnquiryData]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
          marginBottom: "10px",
        }}
      >
        <Download />
      </div>
      <div className="ag-theme-alpine" style={{ height: 300, width: "100%" }}>
        <AgGridReact
          rowData={bookingList}
          columnDefs={enquiryColumnDef}
          onRowClicked={onRowClicked}
        />
        <BasicPagination
          currentPage={allEnquiryPagination.currentPage}
          handlePageChange={handlePageChange}
          pageCount={allEnquiryPagination.pageCount}
        />
        {/* <BasicPagination /> */}
      </div>
    </div>
  );
};

export default NewEnquiry;
