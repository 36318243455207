import { ColDef } from "ag-grid-community";
import LayoutImageCellRenderer from "../../components/pages/projects/cutomImageLayout";
import DateCellRenderer from "../../components/shared/cellRenderer/DateCellRenderer";

export const UpcomingProjectDefs: ColDef[] = [
  {
    field: "address",
    headerName: "Address",
    suppressSizeToFit: true,
  },
  {
    field: "location",
    headerName: "Location",
    suppressSizeToFit: true,
  },
  {
    field: "details",
    headerName: "Details",
  },
  {
    field: "upcomingProject_image",
    headerName: "Project Image",
    suppressSizeToFit: true,
    // cellRenderer: LayoutImageCellRenderer,
    cellRenderer: (params: any) =>
      LayoutImageCellRenderer({
        ...params,
        projectId: params.data._id,
        plotId: "",
        mode: "ALL",
      }),
  },
  {
    field: "createdAt",
    headerName: "Uploaded On",
    cellRenderer: DateCellRenderer,
  },
  {
    field: "updatedAt",
    headerName: "Last Edited ",
    cellRenderer: DateCellRenderer,
    suppressSizeToFit: true,
  },
];
