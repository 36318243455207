export const numberToWords = (num: number): string => {
  const words = [
    'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
    'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen',
    'Eighteen', 'Nineteen'
  ];

  const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

  const scales = ['', 'Thousand', 'Lakh', 'Crore'];

  if (num < 20) return words[num];
  if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 === 0 ? "" : " " + words[num % 10]);
  if (num < 1000) return words[Math.floor(num / 100)] + " hundred" + (num % 100 === 0 ? "" : " and " + numberToWords(num % 100));
  if (num < 100000) return numberToWords(Math.floor(num / 1000)) + " thousand" + (num % 1000 === 0 ? "" : " " + numberToWords(num % 1000));
  if (num < 10000000) return numberToWords(Math.floor(num / 100000)) + " lakh" + (num % 100000 === 0 ? "" : " " + numberToWords(num % 100000));

  return numberToWords(Math.floor(num / 10000000)) + " crore" + (num % 10000000 === 0 ? "" : " " + numberToWords(num % 10000000));
};