import { Box, SelectChangeEvent, Tab, Tabs } from "@mui/material";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import TabPanel from "../../../shared/tabPanel/TabPanel";
import a11yProps from "../../../../constants/tabPanelProps/ayProps";
import UploadLayout from "../../admin/plots/addPlots/UploadLayout";
import { api } from "../../../../utils/api";
import { IProject } from "../../../../@types/interface/Projects";
import { ILayout } from "../../../../@types/interface/layout.interface";
import ProjectEnquiry from "./projectEnquiry/ProjectEnquiry";
import ViewProject from "../../../shared/viewProject/ViewProject";
import ProjectOffers from "./projectOffer/ProjectOffers";
import ProjectAccessibility from "./projectAccessibility/ProjectAccessibility";

const ProjectDetails = () => {
  const [value, setValue] = useState<number>(0);
  const [project, setProject] = useState<IProject | null>(null);
  const [layoutList, setLayoutList] = useState<ILayout[]>([]);
  const [rectanglePositions, setRectanglePositions] = useState<ILayout[]>([]);

  const queryParams = new URLSearchParams(window.location.search);
  const projectId = queryParams.get("pid");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeProjectDetails = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>,
    child?: ReactNode
  ) => {
    const { name, value } = event.target;
    setProject(Object.assign({}, project, { [name]: value }));
  };

  const getProjectDetails = useCallback(async () => {
    const payload = {
      project_object_id: projectId,
    };
    const projectDetails = await api.project.getProjectDetails(payload);
    if (projectDetails) {
      setProject(projectDetails.project_details);
      setLayoutList(projectDetails.layout_list);
      setRectanglePositions(projectDetails.layout_list);
    }
  }, [projectId]);

  const updateProject = async (updatePayload: Partial<IProject>) => {
    const payload = {
      projectId,
      payload: updatePayload,
    };
    const response = await api.project.updateProject(payload);
    if (response) {
      setProject(response);
    }
  };

  useEffect(() => {
    getProjectDetails();
  }, [getProjectDetails]);

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                border: "none",
                backgroundColor: "#558b2f",
              },
            }}
            className="glb-tab-panel"
          >
            <Tab label="Layout Image" {...a11yProps(0)} />
            <Tab label="Project Details" {...a11yProps(1)} />
            <Tab label="Accessibility" {...a11yProps(2)} />
            {/* <Tab label="Notes" {...a11yProps(2)} /> */}
            <Tab label="Total Leads" {...a11yProps(3)} />
            <Tab label="Add Offers" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {projectId ? (
            <UploadLayout
              project={project}
              layoutList={layoutList}
              projectId={projectId}
              setLayoutList={setLayoutList}
              key={0}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {projectId ? (
            <ViewProject project={project} projectId={projectId} />
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {project ? (
            <ProjectAccessibility
              project={project}
              handleChangeProjectDetails={handleChangeProjectDetails}
              updateProject={updateProject}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {projectId ? <ProjectEnquiry projectId={projectId} /> : null}
        </TabPanel>
        <TabPanel value={value} index={4}>
          {projectId && <ProjectOffers projectId={projectId} />}
        </TabPanel>
      </Box>
    </div>
  );
};

export default ProjectDetails;
