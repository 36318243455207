import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { IAminitiesAccordianProps } from "../../../../../../@types/props/aminitiesAccodian.props copy";
import FacilityOverview from "../facility/Facility";

const AminitiesAccordian: React.FC<IAminitiesAccordianProps> = ({
  facility,
  setFacility,
}) => {
  const handleAddMore = () => {
    const lastOverview = facility[facility.length - 1];
    if (lastOverview.facility_details === "") {
      alert("Please fill the previous overview details");
    } else {
      setFacility([
        ...facility,
        {
          facility_details: "",
        },
      ]);
    }
  };

  const handleRemove = (index: number) => {
    if (facility.length === 1) {
      alert("Cannot remove the last item. Please add more items before removing.");
    } else {
      const updatedFacility = facility.filter((_, i) => i !== index);
      setFacility(updatedFacility);
    }
  };
  

  const handleChangeFacility = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const previousOverview = [...facility];
    previousOverview[index] = {
      ...previousOverview[index],
      [name]: value,
    };
    setFacility(previousOverview);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
          Aminities
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {facility.map((faclityItem, index) => (
          <div style={{display:"flex"}}>
          {/* <IconDropdown  index={index}  handleChangeFacility={handleChangeFacility}/> */}
          <FacilityOverview
            facility_details={faclityItem.facility_details}
            key={index}
            index={index}
            handleAddMore={handleAddMore}
            handleRemove={handleRemove}
            handleChangeFacility={handleChangeFacility}
            />
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default AminitiesAccordian;
